import { React, useContext, useEffect, useState, useCallback } from "react";
import { Button, Spinner, useDisclosure, SimpleGrid, Box, Image, Text, Badge, Grid, Flex } from "@chakra-ui/react";
import { isBrowser, isMobile} from "react-device-detect"; 
import { useParams } from "react-router-dom";
import { AppContext } from "../context";
import { useWeb3 } from "../hooks/useWeb3";
import { useNFT } from "../hooks/useNFT";

import NFTTransferModal from "./NFTTransferModal";
import NFTBoostYieldModal from "./NFTBoostYieldModal";

export default function NFTDetail() {
  const {
    web3Provider,
    amount,
    isWalletOpen,
    setIsWalletOpen
  } = useContext(AppContext);

  const { connectWeb3 } = useWeb3();
  const { getNFT, applyNftYieldBoost }      = useNFT();
  const { tokenAddress, tokenId } = useParams(); // Get the NFT ID from the URL!!

  const [nft, setNft] = useState(null);

  const { isOpen: isOpen1,  onOpen: onOpen1,  onClose: onClose1  } = useDisclosure();
  const { isOpen: isOpen2,  onOpen: onOpen2,  onClose: onClose2  } = useDisclosure();

  useEffect(() => {

    const fetchData = async () => {
      let response = await getNFT(tokenAddress, tokenId);

      console.log(tokenAddress, tokenId);
      console.log(nft, response);
      setNft(response);
    }

    fetchData();

  }, [tokenAddress, tokenId]); // Dependency array ensures that fetchData runs when `id` changes


  function formatTimestamp(timestamp) {
    const date = new Date(Number(timestamp)*1000);  // If your timestamp is in seconds, multiply by 
    //console.log(date, timestamp);
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}-${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  }

  return web3Provider ? (

    (isMobile) ? (
      //HERE!!

      (nft) ? (
        <Box
            align="center"                                 
            display="flex"                                 
            flexDirection="column"                         
            height={(isMobile) ? (web3Provider) ? "88.3vh" : "92.3vh"
                               : "92.3vh"}                                              
            css={{                                         
              overflowY: "scroll",                         
              "&::-webkit-scrollbar": {                    
                width: (isMobile) ? '0px' : '16px',                             
              },                                           
              "&::-webkit-scrollbar-track": {              
                backgroundColor: "transparent",            
              },                                           
              "&::-webkit-scrollbar-thumb": {              
                backgroundColor: "rgba(205, 102, 0, 0.5)", 
                borderRadius: "20px",                      
                border: "4px solid transparent",           
                backgroundClip: "content-box",             
              },                                           
              "&::-webkit-scrollbar-thumb:hover": {        
                backgroundColor: "rgba(205, 102, 0, 0.8)", 
              },                                           
            }} 
          >

            <Box position="relative" top="20px" display="flex" w="full" justifyContent="center" alignItems="center">
              <NFTTransferModal nftId={nft.tokenId} isOpen={isOpen1} onClose={onClose1} />
              <NFTBoostYieldModal nftId={nft.tokenId} nftPower={nft.power} isOpen={isOpen2} onClose={onClose2} />

              <Box w="200px">
                <Box key={nft.id} boxShadow="md" borderRadius="1.21rem 1.21rem 1.21rem 1.21rem" w="200px" bg="orange" border="2px" borderColor="green.600">
                  <Image src={nft.tokenImage} alt={nft.name} boxSize="200px" objectFit="cover" borderRadius="1.21rem 1.21rem 0rem 0rem"/>
                  <Flex justifyContent="center" alignItems="center">
                    <Text mt="4px" mb="4px" fontSize="lg" fontWeight="normal" lineHeight="short">
                      {nft.name} #{nft.tokenId}
                    </Text>
                  </Flex>
                </Box>
              </Box>   

            </Box>  
            
            <Box position="relative" mt="20px" top="20px" display="flex" w="full" justifyContent="center" alignItems="center">
              <Box p="10px" borderWidth="1px" borderRadius="1rem 1rem 1rem 1rem" backgroundColor="rgb(180,218,190)"
                   border="2px" borderColor="green.600" w="90vw">
                <Flex justifyContent="left">
                <Text position="relative" mb="4px" fontSize="lg" fontWeight="normal">{nft.name} #{nft.tokenId}</Text>
                </Flex>

                <Flex align="center" justify="space-between" mt="10px">
                  <Box w="42vw" mr="10px">
                    <Button mt="4px"           borderRadius="1.2rem 1.21rem 1.21rem 1.21rem"
                            bg="rgb(220,220,220)" color="rgb(0,122,255)" onClick={onOpen1}>Transfer</Button>
                  </Box>

                  <Box w="42vw" mr="10px">
                    <Button mt="4px" ml="20px" borderRadius="1.2rem 1.21rem 1.21rem 1.21rem"
                            bg="rgb(220,220,220)" color="rgb(0,122,255)" onClick={onOpen2}>Boost yield</Button>
                  </Box>
                </Flex>

                <Flex align="center" justify="space-between" mt="10px">
                  <Box w="42vw" mr="10px" border="1px" borderRadius="1.2rem 1.21rem 1.21rem 1.21rem" borderColor="gray.300" backgroundColor="gray.100">
                    <Flex justifyContent="center" alignItems="center">
                      <Text ml="1rem" mt="0.1rem" mb="0.1rem" fontSize="xl">Level:</Text>
                      <Text ml="1rem" mt="0.1rem" mb="0.1rem" fontSize="xl">{nft.level}</Text>
                    </Flex>
                  </Box>
                  <Box w="42vw" border="1px" borderRadius="1.2rem 1.21rem 1.21rem 1.21rem" borderColor="gray.300" backgroundColor="gray.100">
                    <Flex justifyContent="center" alignItems="center">
                      <Text ml="1rem" mt="0.1rem" mb="0.1rem" fontSize="xl">Power:</Text>
                      <Text ml="1rem" mt="0.1rem" mb="0.1rem" fontSize="xl">{nft.power}</Text>
                    </Flex>
                  </Box>
                </Flex>

                <Flex align="center" justify="center" mt="10px">
                  <Box w="84vw" border="1px" borderRadius="1.2rem 1.21rem 1.21rem 1.21rem" borderColor="gray.300" backgroundColor="gray.100">
                    <Text ml="1rem" mt="0.1rem"             fontSize="xl">Token Address:</Text>
                    <Text           mt="0rem"   mb="0.1rem" fontSize="sm">{nft.tokenAddress}</Text>
                  </Box>
                </Flex>

                <Flex align="center" justify="center" mt="10px">
                  <Box w="84vw" border="1px" borderRadius="1.2rem 1.21rem 1.21rem 1.21rem" borderColor="gray.300" backgroundColor="gray.100">
                    <Text ml="1rem" mt="0.1rem"             fontSize="xl">Create Time:</Text>
                    <Text           mt="0rem"   mb="0.1rem" fontSize="xl">{formatTimestamp(nft.createTimestamp)}</Text>
                  </Box>
                </Flex>
                


              </Box>

            </Box>

        </Box>


      ) : (
        <Box p={5}>
        </Box>
      )

    ) : (

      (nft) ? (
        <Grid
          templateAreas={`"left right"`}
          gridTemplateColumns="1fr 3fr"
          gap="20px"
          p="20px"
        >
          <NFTTransferModal nftId={nft.tokenId} isOpen={isOpen1} onClose={onClose1} />
          <NFTBoostYieldModal nftId={nft.tokenId} nftPower={nft.power} isOpen={isOpen2} onClose={onClose2} />

          <Box gridArea="left" p="10px" w="auto">
            <Box key={nft.id} boxShadow="md" p="0" borderRadius="1.21rem 1.21rem 1.21rem 1.21rem" w="500px" bg="orange" border="2px" borderColor="green.600">
              <Image src={nft.tokenImage} alt={nft.name} boxSize="500px" objectFit="cover" borderRadius="1.21rem 1.21rem 0rem 0rem"/>
              <Flex justifyContent="center" alignItems="center">
                <Text mt="4px" mb="4px" fontSize="xl" fontWeight="normal" lineHeight="short">
                  {nft.name} #{nft.tokenId}
                </Text>
              </Flex>
            </Box>

          </Box>

          <Box gridArea="right" p="10px" display="flex" flexDirection="column">

            <Box p="10px" borderWidth="1px" borderRadius="1rem 1rem 1rem 1rem" backgroundColor="rgb(180,218,190)" border="2px" borderColor="green.600">
              <Text mb="4px" fontSize="xl" fontWeight="normal" >{nft.name} #{nft.tokenId}</Text>
              <Button mt="4px"           borderRadius="1.2rem 1.21rem 1.21rem 1.21rem"
                      bg="rgb(220,220,220)" color="rgb(0,122,255)" onClick={onOpen1}>Transfer</Button>

              <Button mt="4px" ml="20px" borderRadius="1.2rem 1.21rem 1.21rem 1.21rem" 
                      bg="rgb(220,220,220)" color="rgb(0,122,255)" onClick={onOpen2}>Boost yield</Button>

              <Flex align="center" justify="space-between" mt="10px">
                <Box w="28vw" mr="10px" border="1px" borderRadius="1.2rem 1.21rem 1.21rem 1.21rem" borderColor="gray.300" backgroundColor="gray.100">
                  <Text ml="1rem" mt="0.1rem"             fontSize="xl">Level:</Text>
                  <Text ml="1rem" mt="0rem"   mb="0.1rem" fontSize="xl">{nft.level}</Text>
                </Box>
                <Box w="28vw" border="1px" borderRadius="1.2rem 1.21rem 1.21rem 1.21rem" borderColor="gray.300" backgroundColor="gray.100">
                  <Text ml="1rem" mt="0.1rem"             fontSize="xl">Power:</Text>
                  <Text ml="1rem" mt="0rem"   mb="0.1rem" fontSize="xl">{nft.power}</Text>
                </Box>
              </Flex>

              <Flex align="center" justify="space-between" mt="10px">
                <Box w="28vw" border="1px" borderRadius="1.2rem 1.21rem 1.21rem 1.21rem" borderColor="gray.300" backgroundColor="gray.100">
                  <Text ml="1rem" mt="0.1rem"             fontSize="xl">Token Address:</Text>
                  <Text ml="1rem" mt="0rem"   mb="0.1rem" fontSize="xl">{nft.tokenAddress}</Text>
                </Box>

                <Box w="28vw" border="1px" borderRadius="1.2rem 1.21rem 1.21rem 1.21rem" borderColor="gray.300" backgroundColor="gray.100">
                  <Text ml="1rem" mt="0.1rem"             fontSize="xl">Create Time:</Text>
                  <Text ml="1rem" mt="0rem"   mb="0.1rem" fontSize="xl">{formatTimestamp(nft.createTimestamp)}</Text>
                </Box>
              </Flex>
            </Box>

          </Box>

        </Grid>

      ) : (
        <Box p={5}>
        </Box>
      )

    )

  ) : (
    <Flex align="center" justify="center" mt="10px">
      <Box p="0.5rem" bg="white" width={(isMobile) ? "75%" : "20%"} borderRadius="1.25rem 1.25rem 1.25rem 1.25rem">
        <Button
          color="white"
          bg="orange"
          width="100%"
          p="1.62rem"
          borderRadius="1.25rem"
          _hover={{ bg: "orange.400" }}
          onClick={ () => {setIsWalletOpen(true)} }
        >
          Connect Wallet
        </Button>
      </Box>
    </Flex>
  );
}
