import React, { useState, useContext, useEffect } from "react";
import { ethers } from "ethers";
import { Flex, Button, FormControl, Box, FormLabel, Heading, Input, Text, Spacer, Switch, Circle, Image,
         Card, CardHeader, CardBody, CardFooter, Stack, SimpleGrid } from "@chakra-ui/react";
import { SettingsIcon } from '@chakra-ui/icons';
import { isBrowser, isMobile} from "react-device-detect";  
import { isIOS, isAndroid } from "react-device-detect";
import SwitchTab from "./components/SwitchTab";
import FlexButton from "./components/FlexButton";
import { useWeb3 } from "./hooks/useWeb3";
import { usePAN } from "./hooks/usePAN";
import { useIcon } from "./hooks/useIcon";
import { useNFT } from "./hooks/useNFT";

import { AppContext } from "./context";

import logo_cronos from "./assets/cronos_light_bg.png";
import logo from "./assets/creampan.png";
import logo_color3_2 from "./assets/creampan_color3_2.png";
import logo_color2_2 from "./assets/creampan_color2_2.png";
import ptCRO_logo from "./assets/ptCRO.png";
import ytCRO_logo from "./assets/ytCRO.png";
import WCRO_logo from "./assets/WCRO.png";
import NFTGallery from "./components/NFTGallery";
import NFTMint from "./components/NFTMint";

function NFT() {
  const {
    web3Provider,
    walletAddress,
    selectedTab,
    ptCRO_WCRO_address,
    ytCRO_WCRO_address,
    PAN_WCRO_address,
    ptCRO_ADDRESS,
    ytCRO_ADDRESS,
    PAN_address,
    blockNumber,
    nfts
  } = useContext(AppContext);

  const { getPTCROPrice, getYTCROPrice, getPANPrice, getStakeAmount, getTotalStake, getRewards, getAPRFromServer, getTotalSupply, getBalance } = usePAN();
  const { handleExplorer } = useWeb3();
  const { CircleIcon } = useIcon();
  const { getUserNFTs, getUser, addNewUser, getNFT} = useNFT();

  const tabs = [" Mint ", " MyNFT "];

  const [tokenImages, setTokenImages] = useState([
    {url: "https://testnet.creampan.finance/NFT/level1.png",
     level: "1",
     power: "200"
    },
    {url: "https://testnet.creampan.finance/NFT/level2.png",
     level: "2",
     power: "500"      
    },
    {url: "https://testnet.creampan.finance/NFT/level3.png",
     level: "3",
     power: "800"
    },
    {url: "https://testnet.creampan.finance/NFT/level4.png",
     level: "4",
     power: "1200"
    },
    {url: "https://testnet.creampan.finance/NFT/level5.png",
     level: "5",
     power: "1600"
    },
    {url: "https://testnet.creampan.finance/NFT/level6.png",
     level: "6",
     power: "3000"
    }
    ]);

  //regular update states
  useEffect(() => {
    let intervalId;

    const updateStatus = async () => {
      if ((web3Provider)&&(walletAddress.toString()!=="")) {
        await getUserNFTs(walletAddress);
      }

    };
  
    if (web3Provider) {
      updateStatus();
      intervalId = setInterval(updateStatus, 60000);
    }
  
    return () => clearInterval(intervalId);
  }, [web3Provider, walletAddress]);
  ///////////////////////

      return (
        <Box
            align="center"                                 
            display="flex"                                 
            flexDirection="column"                         
            justifyContent="space-between"                 
            height={(isMobile) ? (web3Provider) ? "88.3vh" : "92.3vh"
                               : "92.3vh"}                                              
            css={{                                         
              overflowY: "scroll",                         
              "&::-webkit-scrollbar": {                    
                width: (isMobile) ? '0px' : '16px',                             
              },                                           
              "&::-webkit-scrollbar-track": {              
                backgroundColor: "transparent",            
              },                                           
              "&::-webkit-scrollbar-thumb": {              
                backgroundColor: "rgba(205, 102, 0, 0.5)", 
                borderRadius: "20px",                      
                border: "4px solid transparent",           
                backgroundClip: "content-box",             
              },                                           
              "&::-webkit-scrollbar-thumb:hover": {        
                backgroundColor: "rgba(205, 102, 0, 0.8)", 
              },                                           
            }} 
          >
          <Flex justifyContent="center" alignItems="center">
          <Box position="relative" top="60px" borderRadius="2rem" bg="orange.200" border="2px" borderColor="orange.200" p="0.4rem">
          <SwitchTab tabs={tabs} onChange={()=>{}} />
          </Box>
          </Flex>

          <Box 
            w="auto"
            mx="auto"
            position="relative"
            top="80px"
            boxShadow="rgb(0 0 0 / 0%) 0rem 0.37rem 0.62rem"
            borderRadius="1.25rem"
          >

            { (selectedTab===0) && (
              <Box>
                <NFTMint tokenImages={tokenImages} />
              </Box>
            )}
            { (selectedTab===1) && (
              <Box>
                            
              <Flex>
                <NFTGallery nfts={nfts} />
              </Flex>

              </Box>
            )}

          </Box>
        
        <Flex
          alignItems="center"
          justifyContent="space-evenly">
          <Text mt="10rem" fontSize='35px' fontWeight="bold" fontFamily="Arial">
          </Text>
        </Flex>

        <Flex bottom="0px" justify="space-between" alignItems="center" width="99vw" mb={(isAndroid) ? "0.7rem" : "0.2rem"}>
          
          <Flex mr="16px" w="90px">
          </Flex>

          <Flex alignItems="center">
            <Text fontSize={(isMobile) ? '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color="white" mr="0.4rem" >powered by</Text>
            <Box width="85px" height="30px">
              <Image src={logo_cronos} fit="contain"/>
            </Box>
          </Flex>

          <Flex alignItems="center" w={(web3Provider) ? "" : "90px"} >
            {web3Provider && (
            <Flex alignItems="center">
            <Button h="0px" bg="transparent" fontSize={(isMobile) ? (isAndroid)? '11px' : '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color="white" mr="0.3rem" p="0" onClick={() => handleExplorer( ("/block/" + blockNumber) )}>{blockNumber}</Button>
            <CircleIcon boxSize={3} color="rgb(55, 160, 105)" mr="0px"/>
            </Flex>
            )}
            {!web3Provider && (
            <Flex alignItems="center">
            <Text h="0px" bg="transparent" fontSize={(isMobile) ? (isAndroid)? '11px' : '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color="transparent" mr="0.3rem" p="0">{blockNumber}</Text>
            <CircleIcon boxSize={3} color="transparent" mr="0px"/>
            </Flex>
            )}
          </Flex>
        </Flex>

        </Box>
      );
  
}

export default NFT;
