import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ethers } from "ethers";
import { DeFiWeb3Connector } from "@deficonnect/web3-connector";
import { AppContext } from "../context";

export function useWeb3() {
  const {
    ptCRO_ADDRESS,
    ptCRO_ABI,
    walletAddress,
    web3Provider,
    WCRO_address,
    setWeb3Provider,
    setWeb3JsonRpcProvider,
    setBalanceCRO,
    setBalanceWCRO,
    setBalanceptCRO,
    setBalanceytCRO,
    setBalancePAN,
    amount,
    setAmount,
    isLoading,
    setIsLoading,
    setSelectedSwapTab,
    setWalletAddress,
    setPendingRewards,
    setClaimAddress,
    setSelectedCoin,
    setPanHodlLevel,
    setHoldDiscount,
    setUtilization,
    cronos_nodeUrl,
    setWalletChainId
  } = useContext(AppContext);

  const [web3Status, setWeb3Status] = useState("disconnected");
  const navigate = useNavigate();

  function handleAddLP() {
    setSelectedSwapTab(1);
    navigate('/swap');
  }

  function handleCryptoOrgExplorer(page) {
    let explorer = "https://crypto.org/explorer/croeseid4";
    let openAddress = explorer + page;

    window.open(openAddress, "_blank");
  }

  function handleExplorer(page) {
    let explorer = "https://cronos.org/explorer/testnet3";
    let openAddress = explorer + page;

    window.open(openAddress, "_blank");
  }

  async function connectWeb3_CronosDeFi() {
    try {
      const connect = new DeFiWeb3Connector({
        supportedChainIds: [338],
        appName: "Creampan Finance",
        chainType: "eth",
        chainId: "338",
        rpcUrls: {
          338: cronos_nodeUrl,
        },
        pollingInterval: 15000,
      })

      await connect.activate();
      const connectProvider = await connect.getProvider();
      const provider = new ethers.providers.Web3Provider(connectProvider);
      const signer = await provider.getSigner();
      const currentAddress = await provider.getSigner().getAddress();

      const JsonRpcProvider = new ethers.providers.JsonRpcProvider(cronos_nodeUrl);

      setWalletChainId(provider.provider.networkConfig.chainId);
      setWeb3Provider(provider);
      setWeb3JsonRpcProvider(JsonRpcProvider);

      const ptCRO_contract = new ethers.Contract(ptCRO_ADDRESS, ptCRO_ABI, signer);
      const currentBalanceptCRO = await ptCRO_contract.balanceOf(currentAddress);

      const currentBalance = await provider.getBalance(signer.getAddress());

      const WCRO_contract = new ethers.Contract(WCRO_address, ptCRO_ABI, signer); //use ptCRO_ABI for ERC20 balanceOf query.
      var balanceWCRO = await WCRO_contract.balanceOf(currentAddress);

      setWalletAddress(currentAddress);
      setClaimAddress(currentAddress);
      setBalanceCRO(Number(ethers.utils.formatEther(currentBalance)).toFixed(3));
      setBalanceWCRO(Number(ethers.utils.formatEther(balanceWCRO)).toFixed(3));
      setBalanceptCRO(Number(ethers.utils.formatEther(currentBalanceptCRO)).toFixed(3));
    } catch (error) {
      console.log(error);
    }

  }

  async function connectWeb3() {
    if (window.ethereum) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send('eth_requestAccounts', []);
        const signer = provider.getSigner();
        const currentAddress = await provider.getSigner().getAddress();
        const chainId = await provider.getNetwork();
        const JsonRpcProvider = new ethers.providers.JsonRpcProvider(cronos_nodeUrl);

        await window.ethereum.request({ method: 'eth_requestAccounts' });
        
        setWalletChainId(provider._network.chainId);
        setWeb3Provider(provider);
        setWeb3JsonRpcProvider(JsonRpcProvider);
        
        const ptCRO_contract = new ethers.Contract(ptCRO_ADDRESS, ptCRO_ABI, signer);
        const currentBalanceptCRO = await ptCRO_contract.balanceOf(currentAddress);

        const currentBalance = await provider.getBalance(signer.getAddress());

        const WCRO_contract = new ethers.Contract(WCRO_address, ptCRO_ABI, signer); //use ptCRO_ABI for ERC20 balanceOf query.
        var balanceWCRO = await await WCRO_contract.balanceOf(currentAddress);

        setWalletAddress(currentAddress);
        setClaimAddress(currentAddress);

        setBalanceCRO(Number(ethers.utils.formatEther(currentBalance)).toFixed(3));
        setBalanceWCRO(Number(ethers.utils.formatEther(balanceWCRO)).toFixed(3));
        setBalanceptCRO(Number(ethers.utils.formatEther(currentBalanceptCRO)).toFixed(3));
      } catch (error) {
        console.log(error);
      }
    } else {
      console.error("Web3 provider not found.");
    }
  }

  async function disconnectWeb3() {
    setWeb3Provider("");
    setBalanceCRO(0);
    setBalanceWCRO(0);
    setBalanceptCRO(0);
    setBalanceytCRO(0);
    setBalancePAN(0);
    setAmount("");
    setWalletAddress("");
    setClaimAddress("0");
    setPendingRewards("0");
    setHoldDiscount(0);
    setPanHodlLevel(0);
    setUtilization(0);
    setSelectedCoin({name: "", symbol: "", icon: ""});
  }

  useEffect(() => {
    if (web3Provider) {
      (async function () {
        try {
          await web3Provider.send('eth_requestAccounts', []);
          const signer = web3Provider.getSigner();
          const currentAddress = await web3Provider.getSigner().getAddress();

          const ptCRO_contract = new ethers.Contract(ptCRO_ADDRESS, ptCRO_ABI, signer);
          const currentBalanceptCRO = await ptCRO_contract.balanceOf(currentAddress);
  
          const currentBalance = await web3Provider.getBalance(signer.getAddress());

          setBalanceCRO(Number(ethers.utils.formatEther(currentBalance)).toFixed(3));
          setWalletAddress(currentAddress);

          setWeb3Status("connected");
        } catch (error) {
          console.error(error);
          setWeb3Status("error");
        }
      })();
    }
  }, [web3Provider, walletAddress]);

  return {
    web3Status,
    connectWeb3_CronosDeFi,
    connectWeb3,
    disconnectWeb3,
    handleCryptoOrgExplorer,
    handleExplorer,
    handleAddLP
  };
}
