import {
    Box,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Input,
    Table, Thead, Tbody, Tr, Th, Td, TableCaption, TableContainer,
    Flex,
    Image,
    Text,
    Button,
    Spinner
  } from "@chakra-ui/react";
  import Axios from "axios";
  import { useEffect, useState, useContext } from "react";
  import { useNFT } from "../hooks/useNFT";

  import { AppContext } from "../context";

  export default function NFTTransferModal({nftId, isOpen, onClose}) {
    const [sendTo, setSendTo] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const {
      swapCoin1,
      setSwapCoin1,
      swapCoin2,
      setSwapCoin2,
      PancakeNft_address,
      walletAddress
    } = useContext(AppContext);

    const {safeTransferNFT} = useNFT();

    return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
        <ModalOverlay />
        <ModalContent
          background="orange.100"
          border="0.2rem"
          borderStyle="solid"
          borderColor="orange.300"
          borderRadius="3xl">
          <ModalHeader color="black" px={4} fontSize="lg" fontWeight="bold">
            To: 
          </ModalHeader>
          <ModalCloseButton
            color="black"
            fontSize="sm"
            _hover={{
              color: "gray.600",
            }}
            onClick={(e) => onClose()}
            />
          <ModalBody pt={0} px={4}>
            <Box
              borderRadius="1.25rem"
              border="0.06rem"
              borderStyle="solid"
              borderColor="gray.200"
              bg="rgb(255, 255, 245)"
              p="0.8em">
              <Input
                placeholder="0xa3678b...20d6c"
                fontWeight="500"
                fontSize="1.3rem"
                width="100%"
                size="19rem"
                textAlign="left"
                outline="none"
                border="none"
                focusBorderColor="none"
                borderRadius="0.6rem"
                type="text"
                color="black"
                p="0.3rem"
                style={{ boxShadow: "none" }}
                value={sendTo}
                onChange={(e) => {
                  setSendTo(e.target.value.replace(/[^a-zA-Z0-9]/g, ""));
                }}
                />
            </Box>
            <Flex justifyContent="center" alignItems="center">
              <Button mt="15px" mb="15px" borderRadius="1.2rem 1.21rem 1.21rem 1.21rem" w="200px"
                      bg="rgb(220,220,220)" color="rgb(0,122,255)"
                      rightIcon={isLoading ? <Spinner size="sm" speed='0.8s'/> : null}
                      
                      onClick={async () => {var tx = await safeTransferNFT(PancakeNft_address, walletAddress, sendTo, nftId);
                                            if (typeof tx !== 'undefined') {
                                              setIsLoading(true);
                                              await tx.wait(); //await delay(25000); //Delay 25s
                                              setIsLoading(false);
                                              onClose();
                                            }
                              }} >Send</Button>
            </Flex>
          </ModalBody>
  
          <ModalFooter
            justifyContent="flex-start"
            background="orange.200"
            borderBottomLeftRadius="3xl"
            borderBottomRightRadius="3xl"
            p="1rem">
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
  