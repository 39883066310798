import {
    Box,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Input,
    Table, Thead, Tbody, Tr, Th, Td, TableCaption, TableContainer,
    Flex,
    Image,
    Text,
    Button,
    Spinner
  } from "@chakra-ui/react";
  import Axios from "axios";
  import { isBrowser, isMobile} from "react-device-detect";  
  import { isIOS, isAndroid } from "react-device-detect";
  import { useEffect, useState, useContext } from "react";
  import { useNFT } from "../hooks/useNFT";

  import { AppContext } from "../context";

  export default function NFTBoostYieldModal({nftId, nftPower, isOpen, onClick, onClose}) {
    const [isLoading, setIsLoading] = useState(false);

    const {
      web3Provider,
      PancakeNft_address,
      walletAddress,
      ytCROV2_ADDRESS,
      swapCoin1,
      setSwapCoin1,
      swapCoin2,
      setSwapCoin2,
      balanceytCRO,
      balanceytCROV2,
      V1Checked
    } = useContext(AppContext);

    const {calBoostedAmount, applyNftYieldBoost, checkIsApprovedForAll, setNftApprovalForAll, NftApprove} = useNFT();
    const [allowed, setAllowed] = useState(false);
    
    const checkStatus = async () => {
      console.log("check");
      if (web3Provider) {
        try {
          var result = await checkIsApprovedForAll(PancakeNft_address, walletAddress, ytCROV2_ADDRESS);
          setAllowed( result ); 

        } catch (error) {
          console.error(error);
        }
      }
    };

    const handleApplyNftYieldBoost = async () => {
      try {
        let tx;
        let result = await checkIsApprovedForAll(PancakeNft_address, walletAddress, ytCROV2_ADDRESS);
        setAllowed( result ); 

        if (result) {
          tx = await applyNftYieldBoost(Number(nftId));
          if (typeof tx !== 'undefined') {
            setIsLoading(true);
            await tx.wait(); 
            setIsLoading(false);
            onClose();
          }
        }

        if (!allowed) {
          tx = await setNftApprovalForAll(PancakeNft_address, ytCROV2_ADDRESS, 'true');
          //tx = await NftApprove(PancakeNft_address, ytCROV2_ADDRESS, Number(nftId));
          
          if (typeof tx !== 'undefined') {
            setIsLoading(true);
            await tx.wait(); 
            setIsLoading(false);
            checkStatus();
          }
        }

      } catch (error) {
        console.error(error);
      }

    };

    useEffect(() => {
  
      checkStatus();
  
    }, [allowed, isOpen, onClose]); // Dependency array ensures that fetchData runs when `id` changes
  


    return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
        <ModalOverlay />
        <ModalContent
          background="orange.100"
          border="0.2rem"
          borderStyle="solid"
          borderColor="orange.300"
          borderRadius="3xl">
          <ModalHeader color="black" px={4} fontSize="lg" fontWeight="bold">
            Yield Boost: 
          </ModalHeader>
          <ModalCloseButton
            color="black"
            fontSize="sm"
            _hover={{
              color: "gray.600",
            }}
            onClick={(e) => onClose()}
            />
          <ModalBody pt={0} px={2}>

          { (isMobile) && 
          
            <Box
              borderRadius="1.25rem"
              border="0rem"
              borderStyle="solid"
              borderColor="gray.200"
              p="0em">

              <Flex alignItems="center"
                    justifyContent="space-around">

                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  bg="rgb(247, 248, 250)"
                  p="0.4rem 0.4rem 0.4rem"
                  borderRadius="0.7rem" border="0.06rem solid rgb(237, 238, 242)"
                  _hover={{ border: "0.06rem solid rgb(211,211,211)" }}
                  w="43vw"
                  >
                  <Box width="22vw" borderColor="black">
                    <Text
                      color="black"
                      fontWeight="bold"
                      fontSize="0.9rem">
                      Token ID: 
                    </Text>
                  </Box>

                  <Box width="90px">
                    <Text
                      fontWeight="500"
                      fontSize="1.0rem"
                      width="100%"
                      size="19rem"
                      bg="rgb(247, 248, 250)"
                      textAlign="right"
                      outline="none"
                      border="none"
                      focusBorderColor="none"
                      borderRadius="0.25rem"
                      color="black"
                    >{nftId}</Text>
                  </Box>

                </Flex>

                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  bg="rgb(247, 248, 250)"
                  p={(isMobile) ? "0.4rem 0.4rem 0.4rem" : "0.4rem 0.4rem 0.4rem"}
                  borderRadius="0.7rem" border="0.06rem solid rgb(237, 238, 242)"
                  _hover={{ border: "0.06rem solid rgb(211,211,211)" }}
                  w="47vw"
                  >
                  <Box width="26vw" borderColor="black">
                    <Text
                      color="black"
                      fontWeight="bold"
                      fontSize="0.9rem">
                      My Amount: 
                    </Text>
                  </Box>

                  <Box width="90px">
                    <Text
                      fontWeight="500"
                      fontSize="1.0rem"
                      width="100%"
                      size="19rem"
                      bg="rgb(247, 248, 250)"
                      textAlign="right"
                      outline="none"
                      border="none"
                      focusBorderColor="none"
                      borderRadius="0.25rem"
                      color="black"
                    >{Number(balanceytCROV2).toFixed(1)}</Text>
                  </Box>

                </Flex>

              </Flex>

              <Flex alignItems="center"
                    justifyContent="space-around"
                    p="0.8rem 0rem 0rem">

                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  bg="rgb(247, 248, 250)"
                  p={(isMobile) ? "0.4rem 0.4rem 0.4rem" : "0.4rem 0.4rem 0.4rem"}
                  borderRadius="0.7rem" border="0.06rem solid rgb(237, 238, 242)"
                  _hover={{ border: "0.06rem solid rgb(211,211,211)" }}
                  w="43vw"
                  >
                  <Box width="22vw" borderColor="blue">
                    <Text
                      color="black"
                      fontWeight="bold"
                      fontSize="0.9rem">
                      Boost Rate: 
                    </Text>
                  </Box>

                  <Box width="90px">
                    <Text
                      fontWeight="500"
                      fontSize="1.0rem"
                      width="100%"
                      size="19rem"
                      bg="rgb(247, 248, 250)"
                      textAlign="right"
                      outline="none"
                      border="none"
                      focusBorderColor="none"
                      borderRadius="0.25rem"
                      color="black"
                    >{Number(Number(nftPower)/100)}%</Text>
                  </Box>

                </Flex>

                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  bg="rgb(247, 248, 250)"
                  p={(isMobile) ? "0.4rem 0.4rem 0.4rem" : "0.4rem 0.4rem 0.4rem"}
                  borderRadius="0.7rem" border="0.06rem solid rgb(237, 238, 242)"
                  _hover={{ border: "0.06rem solid rgb(211,211,211)" }}
                  w="47vw"
                  >
                  <Box width="26vw" borderColor="blue">
                    <Text
                      color="black"
                      fontWeight="bold"
                      fontSize="0.9rem">
                      Boost Amount: 
                    </Text>
                  </Box>

                  <Box width="90px">
                    <Text
                      fontWeight="500"
                      fontSize="1.0rem"
                      width="100%"
                      size="19rem"
                      bg="rgb(247, 248, 250)"
                      textAlign="right"
                      outline="none"
                      border="none"
                      focusBorderColor="none"
                      borderRadius="0.25rem"
                      color="black"
                    >{calBoostedAmount(balanceytCROV2, Number(nftPower))}</Text>
                  </Box>

                </Flex>

              </Flex>

            </Box>

          }

          { (!isMobile) && 

            <Box
              borderRadius="1.25rem"
              border="0rem"
              borderStyle="solid"
              borderColor="gray.200"
              p="0em">

              <Flex alignItems="center"
                    justifyContent="space-around">

                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  bg="rgb(247, 248, 250)"
                  p={(isMobile) ? "0.4rem 0.4rem 0.4rem" : "0.4rem 0.4rem 0.4rem"}
                  borderRadius="0.7rem" border="0.06rem solid rgb(237, 238, 242)"
                  _hover={{ border: "0.06rem solid rgb(211,211,211)" }}>
                  <Box width="8vw" borderColor="black" border="3px">
                    <Text
                      color="black"
                      fontWeight="bold">
                      Token ID: 
                    </Text>
                  </Box>

                  <Box width="90px">
                    <Text
                      fontWeight="500"
                      fontSize="1.4rem"
                      width="100%"
                      size="19rem"
                      bg="rgb(247, 248, 250)"
                      textAlign="right"
                      outline="none"
                      border="none"
                      focusBorderColor="none"
                      borderRadius="0.25rem"
                      color="black"
                    >{nftId}</Text>
                  </Box>

                </Flex>

                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  bg="rgb(247, 248, 250)"
                  p={(isMobile) ? "0.4rem 0.4rem 0.4rem" : "0.4rem 0.4rem 0.4rem"}
                  borderRadius="0.7rem" border="0.06rem solid rgb(237, 238, 242)"
                  _hover={{ border: "0.06rem solid rgb(211,211,211)" }}>
                  <Box width="8vw" borderColor="black" border="3px">
                    <Text
                      color="black"
                      fontWeight="bold">
                      My Amount: 
                    </Text>
                  </Box>

                  <Box width="90px">
                    <Text
                      fontWeight="500"
                      fontSize="1.4rem"
                      width="100%"
                      size="19rem"
                      bg="rgb(247, 248, 250)"
                      textAlign="right"
                      outline="none"
                      border="none"
                      focusBorderColor="none"
                      borderRadius="0.25rem"
                      color="black"
                    >{Number(balanceytCROV2).toFixed(1)}</Text>
                  </Box>

                </Flex>

              </Flex>

              <Flex alignItems="center"
                    justifyContent="space-around"
                    p="0.8rem 0rem 0rem">

                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  bg="rgb(247, 248, 250)"
                  p={(isMobile) ? "0.4rem 0.4rem 0.4rem" : "0.4rem 0.4rem 0.4rem"}
                  borderRadius="0.7rem" border="0.06rem solid rgb(237, 238, 242)"
                  _hover={{ border: "0.06rem solid rgb(211,211,211)" }}>
                  <Box width="8vw" borderColor="blue" border="3px">
                    <Text
                      color="black"
                      fontWeight="bold">
                      Boost Rate: 
                    </Text>
                  </Box>

                  <Box width="90px">
                    <Text
                      fontWeight="500"
                      fontSize="1.4rem"
                      width="100%"
                      size="19rem"
                      bg="rgb(247, 248, 250)"
                      textAlign="right"
                      outline="none"
                      border="none"
                      focusBorderColor="none"
                      borderRadius="0.25rem"
                      color="black"
                    >{Number(Number(nftPower)/100)}%</Text>
                  </Box>

                </Flex>

                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  bg="rgb(247, 248, 250)"
                  p={(isMobile) ? "0.4rem 0.4rem 0.4rem" : "0.4rem 0.4rem 0.4rem"}
                  borderRadius="0.7rem" border="0.06rem solid rgb(237, 238, 242)"
                  _hover={{ border: "0.06rem solid rgb(211,211,211)" }}>
                  <Box width="8vw" borderColor="blue" border="3px">
                    <Text
                      color="black"
                      fontWeight="bold">
                      Boost Amount: 
                    </Text>
                  </Box>

                  <Box width="90px">
                    <Text
                      fontWeight="500"
                      fontSize="1.4rem"
                      width="100%"
                      size="19rem"
                      bg="rgb(247, 248, 250)"
                      textAlign="right"
                      outline="none"
                      border="none"
                      focusBorderColor="none"
                      borderRadius="0.25rem"
                      color="black"
                    >{calBoostedAmount(balanceytCROV2, Number(nftPower))}</Text>
                  </Box>

                </Flex>

              </Flex>

            </Box>

          }

            <Flex justifyContent="center" alignItems="center">

              { (allowed===true) && <Button mt="15px" mb="15px" bg="rgb(220,220,220)" color="#007AFF" _hover={{ bg: "rgb(160,190,190)" }} borderRadius="1.2rem 1.21rem 1.21rem 1.21rem" w="200px"
                                            rightIcon={isLoading ? <Spinner size="sm" speed='0.8s'/> : null}
                                            onClick={async () => {handleApplyNftYieldBoost(); }}
                                    >Boost
                                    </Button> }

              { (allowed===false) && <Button mt="15px" mb="15px" bg="rgb(255,0,122)" color="white" _hover={{ bg: "rgb(160,190,190)" }} borderRadius="1.2rem 1.21rem 1.21rem 1.21rem" w="200px"
                                             rightIcon={isLoading ? <Spinner size="sm" speed='0.8s'/> : null}
                                             onClick={async () => {handleApplyNftYieldBoost(); }}
                                    >Approve
                                    </Button>   }


            </Flex>

          </ModalBody>
  
          <ModalFooter
            justifyContent="flex-start"
            background="orange.200"
            borderBottomLeftRadius="3xl"
            borderBottomRightRadius="3xl"
            p="1rem">
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
  