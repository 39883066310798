import React, { useContext, useState, useEffect } from "react";
import { Box, Flex, Button, Text, Card, CardHeader, CardBody, CardFooter, Stack, Heading, Divider, StackDivider, useWhyDidYouUpdate, Image, Icon, useDisclosure,
         IconButton, useToast, Tooltip } from "@chakra-ui/react";
import { CopyIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { isBrowser, isMobile} from "react-device-detect";
import { isIOS, isAndroid } from "react-device-detect";
import { ethers } from "ethers";
import { useWeb3 } from "./hooks/useWeb3";
import { usePAN } from "./hooks/usePAN";
import { useIcon } from "./hooks/useIcon";

import AccountModal from "./components/AccountModal";
import CustomSwitchLarge  from "./components/CustomSwitchLarge";

import axios from 'axios';
import logo_cronos from "./assets/cronos_light_bg.png";

import { AppContext } from "./context";

function Dashboard() {
  const {
    ptCRO_ADDRESS,
    ptCRO_ABI,
    ytCRO_ADDRESS,
    WCRO_address,
    ytCRO_ABI,
    Factory_ADDRESS,
    Factory_ABI,
    ptCROV2_ADDRESS,
    ytCROV2_ADDRESS,
    FactoryV2_ADDRESS,
    ptCROV2_ABI,
    ytCROV2_ABI,
    FactoryV2_ABI,
    PAN_address,
    PAN_ABI,
    cro_apiUrl,
    cro_delegator_address,
    cronos_nodeUrl,
    web3Provider,
    setWeb3Provider,
    ptCROContract,
    setptCROContract,
    balanceCRO,
    balanceWCRO,
    setBalanceCRO,
    balanceptCRO,
    setBalanceptCRO,
    balanceytCRO,
    setBalanceytCRO,
    balancePAN,
    setBalancePAN,
    price_ptCRO,
    price_ytCRO,
    price_PAN,
    pendingRewards,
    pendingRewardsV2,
    setPendingRewards,
    amount,
    setAmount,
    flexibleCRO,
    flexibleCROV2,
    setFlexibleCRO,
    isLoading,
    setIsLoading,
    walletAddress,
    setWalletAddress,
    ytTotalSupply,
    setYtTotalSupply,
    ytTotalSupplyV2,
    setYtTotalSupplyV2,
    delegationRaio,
    delegationRaioV2,
    setDelegationRatio,
    setDelegationRatioV2,
    blockNumber,
    color,
    color2,
    aprCRO,
    panHodlLevel,
    stakedCRO,
    unstakingCRO,
    V1Checked, setV1Checked,
    balanceptCROV2,
    balanceytCROV2,
    price_ptCROV2,
    price_ytCROV2,
    stakedCROV2,
    unstakingCROV2
  } = useContext(AppContext);

  const { isOpen: isOpenStake, onOpen: onOpenStake, onClose: onCloseStake } = useDisclosure();

  const { handleCryptoOrgExplorer, handleExplorer } = useWeb3();
  const { getStakeAmount, getRewards } = usePAN();
  const { CircleIcon } = useIcon();

  const [myStakedPAN, setMyStakedPAN]   = useState(0);
  const [myPANRewards, setMyPANRewards] = useState(0);

  const toast = useToast();

  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

  const toggleSwitch = () => setV1Checked(!V1Checked);

  const handleCopyClick = (desc, text) => {
    navigator.clipboard.writeText(text);
    toast({
      title: desc + " address copied!",
      description: text,
      status: "success",
      duration: 2500,
      isClosable: true,
      containerStyle: {
        width: (isMobile) ? "96vw" : "" ,
        fontSize: (isMobile) ? "14px" : "" ,
      },
    });
  };

  async function getTotalPANRewards() {
    var totalReward=0;
    for (let i=0; i < 14; i++)
    {
      var newReward = await getRewards(i);
      //console.log(newReward);
      totalReward += Number(newReward);
    }
    return (Number(totalReward).toFixed(3));
  }

  async function getTotalSupply() {
    const provider = new ethers.providers.JsonRpcProvider(cronos_nodeUrl);

    const ytCRO_contract = new ethers.Contract(ytCRO_ADDRESS, ytCRO_ABI, provider);
    var ytTotalSupply = await ytCRO_contract.totalSupply();

    setYtTotalSupply(Number(ethers.utils.formatEther(ytTotalSupply)).toFixed(3));

    //V2
    const ytCROV2_contract = new ethers.Contract(ytCROV2_ADDRESS, ytCROV2_ABI, provider);
        ytTotalSupply = await ytCROV2_contract.totalSupply();

    setYtTotalSupplyV2(Number(ethers.utils.formatEther(ytTotalSupply)).toFixed(3));

    //
  }

  async function getDelegationRatio() {
    const provider = new ethers.providers.JsonRpcProvider(cronos_nodeUrl);

    const Factory_contract = new ethers.Contract(Factory_ADDRESS, Factory_ABI, provider);
    var currentDelegatioRatio = await Factory_contract.getDelegationRatio();

    setDelegationRatio(Number(Number(currentDelegatioRatio) / 1e4 ).toFixed(2));

    //V2
    const FactoryV2_contract = new ethers.Contract(FactoryV2_ADDRESS, FactoryV2_ABI, provider);
        currentDelegatioRatio = await FactoryV2_contract.getDelegationRatio();

    setDelegationRatioV2(Number(Number(currentDelegatioRatio) / 1e4 ).toFixed(2));
    //
  }

//  const [refreshInterval, setRefreshInterval] = useState(null);

  //regular update states
  useEffect(() => {
    let intervalId;
    let stakedAmount;
    let totalPANRewards;

    const updateBalance = async () => {
      if (web3Provider) {
        try {
          await getTotalSupply();
          await getDelegationRatio();

          stakedAmount = await getStakeAmount("0");
          setMyStakedPAN(stakedAmount);
          
          totalPANRewards = await getTotalPANRewards();
          setMyPANRewards(totalPANRewards);

        } catch (error) {
          console.error(error);
        }
      }
    };
  
    if (web3Provider) {
      updateBalance();
      intervalId = setInterval(updateBalance, 20000);
    }
    else {
      setMyStakedPAN(0);
      setMyPANRewards(0);
    }
  
    return () => clearInterval(intervalId);
  }, [web3Provider]);
  ///////////////////////

  return (
    <Box
    className="container"
    display="flex"
    flexDirection="column"
    justifyContent="space-between"
    height={(isMobile) ? ((web3Provider) ? "88.3vh" : "92.3vh") : "92.3vh"}
    css={{ 
          overflowY: "scroll",
          "&::-webkit-scrollbar": {                    
            width: (isMobile) ? '0px' : '16px',                             
          },       
        }}
    >
    <AccountModal isOpen={isOpenStake} onClose={onCloseStake} />

    <Flex
    alignItems="center"
    justifyContent="space-evenly"
    flexDirection={(isMobile) ? "column" : "row"}
    >
      <Card 
          position="relative"
          top="100px"
          width={(isMobile) ? "85%" : "20%"}
          height={(isMobile) ? "" : "100%"}
          bg="rgb(250, 250, 250)"
          borderRadius="1.25rem"
          border="0.1rem solid rgb(200, 200, 200)"
          mb={(isMobile) ? "3rem" : "0rem"} 
          >
        <CardHeader>

          <Flex alignItems="center" justifyContent="space-between">
            <Heading size='lg' color="#551a8b">Creampan Pool: </Heading>
            <CustomSwitchLarge checkedBackground="rgb(52,93,157)" uncheckedBackground="rgb(6,95,70)" isChecked={V1Checked} toggleSwitch={toggleSwitch} />
          </Flex>

        </CardHeader>

        <CardBody>
        <Stack spacing='3'>
          <Box>

            <Flex alignItems="center">
              <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                {V1Checked ? "V1" : "V2" }&nbsp;
              </Heading>

              <Heading fontSize={(isMobile) ? '18px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
              Total staked CRO: 
              </Heading>
              <ExternalLinkIcon boxSize={5} color="gray" ml="0.3rem" style={{ cursor: 'pointer' }} onClick={onOpenStake}/>
            </Flex>

            <Text pt='1' fontSize={(isMobile) ? '18px' : '20px'} fontWeight="bold">
            {V1Checked ? stakedCRO : stakedCROV2 }
            </Text>
          </Box>
          <Divider borderColor="orange" borderWidth="1px" borderRadius="3rem"/>
          <Box>

            <Flex alignItems="center">
              <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                {V1Checked ? "V1" : "V2" }&nbsp;
              </Heading>

              <Heading fontSize={(isMobile) ? '18px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
              Pool flexible CRO: 
              </Heading>
              <ExternalLinkIcon boxSize={5} color="gray" ml="0.3rem" style={{ cursor: 'pointer' }} 
                                onClick={() => V1Checked ? handleExplorer( ("/address/"+Factory_ADDRESS) ) : handleExplorer( ("/address/"+FactoryV2_ADDRESS) ) }/>
            </Flex>

            <Text pt='1' fontSize={(isMobile) ? '18px' : '20px'} fontWeight="bold">
            {V1Checked ? flexibleCRO : flexibleCROV2 }
            </Text>
          </Box>
          <Divider borderColor="orange" borderWidth="1px" borderRadius="3rem"/>
          <Box>

            <Flex alignItems="center">
              <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                {V1Checked ? "V1" : "V2" }&nbsp;
              </Heading>

              <Heading fontSize={(isMobile) ? '18px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
              Total unstaking CRO: 
              </Heading>
              <ExternalLinkIcon boxSize={5} color="gray" ml="0.3rem" style={{ cursor: 'pointer' }} onClick={onOpenStake}/>
            </Flex>            

            <Text pt='1' fontSize={(isMobile) ? '18px' : '20px'} fontWeight="bold">
            {V1Checked ? unstakingCRO : unstakingCROV2 }
            </Text>
          </Box>
          <Divider borderColor="orange" borderWidth="1px" borderRadius="3rem"/>
          <Box>
            <Heading fontSize={(isMobile) ? '18px' : '20px'} color="gray">
            Real staking yield: 
            </Heading>
            <Text pt='2'fontSize={(isMobile) ? '18px' : '20px'} fontWeight="bold">
            {aprCRO}%
            </Text>
          </Box>                    
          <Divider borderColor="orange" borderWidth="1px" borderRadius="3rem"/>
          <Box>
            <Flex alignItems="center">
              <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                {V1Checked ? "V1" : "V2" }&nbsp;
              </Heading>
              <Heading fontSize={(isMobile) ? '18px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                Delegation Ratio: 
              </Heading>
            </Flex>
            <Text pt='1' fontSize={(isMobile) ? '18px' : '20px'} fontWeight="bold">
            {V1Checked ? delegationRaio : delegationRaioV2 }
            </Text>
          </Box>                    
          <Divider borderColor="orange" borderWidth="1px" borderRadius="3rem"/>
        </Stack>
        </CardBody>
      </Card>

      <Card 
          position="relative"
          top="100px"
          width={(isMobile) ? "85%" : "20%"}
          height={(isMobile) ? "" : "100%"}
          bg="rgb(250, 250, 250)"
          borderRadius="1.25rem"
          border="0.1rem solid rgb(200, 200, 200)"
          mb={(isMobile) ? "3rem" : "0rem"} 
          >
        <CardHeader>
          <Flex alignItems="center" justifyContent="space-between">
            <Heading size='lg' color="#551a8b">My Dashboard: </Heading>
            <CustomSwitchLarge checkedBackground="rgb(52,93,157)" uncheckedBackground="rgb(6,95,70)" isChecked={V1Checked} toggleSwitch={toggleSwitch} />
          </Flex>
        </CardHeader>

        <CardBody>
          <Stack spacing='3'>
            <Box>
              <Heading fontSize={(isMobile) ? '16px' : '20px'} color="gray">
              Balance of CRO: 
              </Heading>
              <Text pt='1' fontSize={(isMobile) ? '16px' : '20px'} fontWeight="bold">
              {balanceCRO}
              </Text>
            </Box>
            <Divider borderColor="orange" borderWidth="1px" borderRadius="3rem"/>

            <Box>

              <Flex alignItems="center">
                <Heading fontSize={(isMobile) ? '16px' : '20px'} color="gray">
                Balance of WCRO: 
                </Heading>

                <Text fontSize={(isMobile) ? '16px' : '18px'} fontWeight="bold" color="gray" style={{ cursor: 'pointer' }} onClick={ () => handleCopyClick("WCRO", WCRO_address) } >&nbsp;address</Text>

                <IconButton
                  aria-label="Copy Address"
                  borderRadius="1.25rem"
                  icon={<CopyIcon />}
                  bg="transparent"
                  color="gray"
                  _hover={{ bg: "rgb(200,200,200)" }}
                  size="sm"
                  ml={0.5}
                  onClick={ () => handleCopyClick("WCRO", WCRO_address) }
                />

              </Flex>

              <Text pt='1' fontSize={(isMobile) ? '16px' : '20px'} fontWeight="bold">
              {balanceWCRO}
              </Text>
            </Box>
            <Divider borderColor="orange" borderWidth="1px" borderRadius="3rem"/>

            <Box>

              <Flex alignItems="center">
                <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                Balance of ptCRO
                </Heading>
                <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                &nbsp;{V1Checked ? "V1" : "V2" }:
                </Heading>

                <Text fontSize={(isMobile) ? '16px' : '18px'} fontWeight="bold" color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" } 
                      style={{ cursor: 'pointer' }}
                      onClick={ () => (V1Checked) ? handleCopyClick("ptCRO V1", ptCRO_ADDRESS) : handleCopyClick("ptCRO V2", ptCROV2_ADDRESS) } > 
                &nbsp;address
                </Text>

                <IconButton
                  aria-label="Copy Address"
                  borderRadius="1.25rem"
                  icon={<CopyIcon />}
                  bg="transparent"
                  color={V1Checked ? "rgb(148,99,49)" : "rgb(74,67,168)" }
                  _hover={{ bg: "rgb(200,200,200)" }}
                  size="sm"
                  ml={0.5}
                  onClick={ () => (V1Checked) ? handleCopyClick("ptCRO V1", ptCRO_ADDRESS) : handleCopyClick("ptCRO V2", ptCROV2_ADDRESS) }
                />
                
              </Flex>

              <Text pt='1' fontSize={(isMobile) ? '16px' : '20px'} fontWeight="bold">
              {V1Checked ? balanceptCRO : balanceptCROV2 }
              </Text>
            </Box>
            <Divider borderColor="orange" borderWidth="1px" borderRadius="3rem"/>
            <Box>

              <Flex alignItems="center">
                <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                Balance of ytCRO
                </Heading>

                <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                &nbsp;{V1Checked ? "V1" : "V2" }:
                </Heading>

                <Text fontSize={(isMobile) ? '16px' : '18px'} fontWeight="bold" color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }
                      style={{ cursor: 'pointer' }}
                      onClick={ () => (V1Checked) ? handleCopyClick("ytCRO V1", ytCRO_ADDRESS) : handleCopyClick("ytCRO V2", ytCROV2_ADDRESS) } >
                &nbsp;address
                </Text>

                <IconButton
                  aria-label="Copy Address"
                  borderRadius="1.25rem"
                  icon={<CopyIcon />}
                  bg="transparent"
                  color={V1Checked ? "rgb(148,99,49)" : "rgb(74,67,168)" }
                  _hover={{ bg: "rgb(200,200,200)" }}
                  size="sm"
                  ml={0.5}
                  onClick={ () => (V1Checked) ? handleCopyClick("ytCRO V1", ytCRO_ADDRESS) : handleCopyClick("ytCRO V2", ytCROV2_ADDRESS) }
                />

              </Flex>

              <Text pt='1' fontSize={(isMobile) ? '16px' : '20px'} fontWeight="bold">
              {V1Checked ? balanceytCRO : balanceytCROV2 }
              </Text>
            </Box>
            <Divider borderColor="orange" borderWidth="1px" borderRadius="3rem"/>
            <Box>
              <Flex alignItems="center">

              <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                {V1Checked ? "V1" : "V2" }&nbsp;
              </Heading>              
              <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                Pending CRO rewards:
              </Heading>

              </Flex>

              <Text pt='1' fontSize={(isMobile) ? '16px' : '20px'} fontWeight="bold">
              {V1Checked ? pendingRewards : pendingRewardsV2}
              </Text>
            </Box>
            <Divider borderColor="orange" borderWidth="1px" borderRadius="3rem"/>
          </Stack>
        </CardBody>
      </Card>

      <Card 
          position="relative"
          top="100px"
          width={(isMobile) ? "85%" : "20%"}
          height={(isMobile) ? "" : "100%"}
          bg="rgb(250, 250, 250)"
          borderRadius="1.25rem"
          border="0.1rem solid rgb(200, 200, 200)"
          mb={(isMobile) ? "3rem" : "0rem"} 
          >
        <CardHeader>
          <Heading size='lg' color="#551a8b">My PAN: </Heading>
        </CardHeader>

        <CardBody>
          <Stack spacing='3'>
            <Box>

              <Flex alignItems="center">
                <Heading fontSize={(isMobile) ? '18px' : '20px'} color="gray">
                Balance of PAN: 
                </Heading>

                <Text fontSize={(isMobile) ? '16px' : '18px'} fontWeight="bold" color="gray" style={{ cursor: 'pointer' }} onClick={ () => handleCopyClick("PAN", PAN_address) } >&nbsp;address</Text>

                <IconButton
                  aria-label="Copy Address"
                  borderRadius="1.25rem"
                  icon={<CopyIcon />}
                  bg="transparent"
                  color="gray"
                  _hover={{ bg: "rgb(200,200,200)" }}
                  size="sm"
                  ml={0.5}
                  onClick={ () => handleCopyClick("PAN", PAN_address) }
                />

              </Flex>

              <Text pt='1' fontSize={(isMobile) ? '18px' : '20px'} fontWeight="bold">
              {balancePAN}
              </Text>
            </Box>
            <Divider borderColor="orange" borderWidth="1px" borderRadius="3rem"/>
            <Box>
              <Heading fontSize={(isMobile) ? '18px' : '20px'} color="gray">
              Staked PAN:
              </Heading>
              <Text pt='1' fontSize={(isMobile) ? '18px' : '20px'} fontWeight="bold">
              {myStakedPAN}
              </Text>
            </Box>
            <Divider borderColor="orange" borderWidth="1px" borderRadius="3rem"/>
            <Box>
              <Heading fontSize={(isMobile) ? '18px' : '20px'} color="gray">
              Pending PAN rewards:
              </Heading>
              <Text pt='1' fontSize={(isMobile) ? '18px' : '20px'} fontWeight="bold">
              {myPANRewards}
              </Text>
            </Box>
            <Divider borderColor="orange" borderWidth="1px" borderRadius="3rem"/>
            <Box>
              <Heading size='md' color="gray">
              PAN hodl:
              </Heading>
              <Text pt='1' fontSize={(isMobile) ? '18px' : '20px'} fontWeight="bold">
              Level {panHodlLevel}
              </Text>
            </Box>
            <Divider borderColor="orange" borderWidth="1px" borderRadius="3rem"/>
          </Stack>
        </CardBody>
      </Card>

      <Card 
          position="relative"
          top="100px"
          width={(isMobile) ? "85%" : "20%"}
          height={(isMobile) ? "" : "100%"}
          bg="rgb(250, 250, 250)"
          borderRadius="1.25rem"
          border="0.1rem solid rgb(200, 200, 200)"
          mb={(isMobile) ? "3rem" : "0rem"} 
          >
        <CardHeader>

          <Flex alignItems="center" justifyContent="space-between">
            <Heading size='lg' color="#551a8b">Prices: </Heading>
            <CustomSwitchLarge checkedBackground="rgb(52,93,157)" uncheckedBackground="rgb(6,95,70)" isChecked={V1Checked} toggleSwitch={toggleSwitch} />
          </Flex>

        </CardHeader>

        <CardBody>
          <Stack spacing='3'>
            <Box>
              <Heading fontSize={(isMobile) ? '18px' : '20px'} color="gray">
              1 PAN: 
              </Heading>
              <Text pt='1' fontSize={(isMobile) ? '18px' : '20px'} fontWeight="bold">
              {Number(price_PAN).toFixed(3)} CRO
              </Text>
            </Box>
            <Divider borderColor="orange" borderWidth="1px" borderRadius="3rem"/>
            <Box>
              <Flex>
                <Heading fontSize={(isMobile) ? '18px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                1 ptCRO
                </Heading>
                <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                  &nbsp;{V1Checked ? "V1" : "V2" }:
                </Heading>
              </Flex>

              <Text pt='1' fontSize={(isMobile) ? '18px' : '20px'} fontWeight="bold">
              {V1Checked ? Number(price_ptCRO).toFixed(3) : Number(price_ptCROV2).toFixed(3) } CRO
              </Text>
            </Box>
            <Divider borderColor="orange" borderWidth="1px" borderRadius="3rem"/>
            <Box>
              <Flex>
                <Heading fontSize={(isMobile) ? '18px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                1 ytCRO
                </Heading>
                <Heading fontSize={(isMobile) ? '16px' : '20px'} color={V1Checked ? "rgb(52,93,157)" : "rgb(6,95,70)" }>
                  &nbsp;{V1Checked ? "V1" : "V2" }:
                </Heading>
              </Flex>

              <Text pt='1' fontSize={(isMobile) ? '18px' : '20px'} fontWeight="bold">
              {V1Checked ? Number(price_ytCRO).toFixed(3) : Number(price_ytCROV2).toFixed(3) } CRO
              </Text>
            </Box>
            <Divider borderColor="orange" borderWidth="1px" borderRadius="3rem"/>
          </Stack>
        </CardBody>
      </Card>

      {(isMobile) && (
      <Flex w="30px" h="5rem">
      </Flex>
      )}

    </Flex>

    
    <Flex bottom="0px" justify="space-between" alignItems="center" width="100vw" mb={(isAndroid) ? "1.7rem" : "0.2rem"}>

      <Flex mr="16px" w="90px">
      </Flex>

      <Flex alignItems="center">
        <Text fontSize={(isMobile) ? '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color="white" mr="0.4rem" >powered by</Text>
        <Box width="85px" height="30px">
          <Image src={logo_cronos} fit="contain"/>
        </Box>
      </Flex>

      <Flex alignItems="center" w={(web3Provider) ? "" : "90px"}>
        {web3Provider && (
        <Flex alignItems="center">
        <Button h="0px" bg="transparent" fontSize={(isMobile) ? (isAndroid)? '11px' : '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color={color2} mr="0.3rem" p="0" onClick={() => handleExplorer( ("/block/" + blockNumber) )}>{blockNumber}</Button>
        <CircleIcon boxSize={3} color={color} mr={(isMobile) ? '0px' : '16px'}/>
        </Flex>
        )}
        {!web3Provider && (
        <Flex alignItems="center">
        <Text h="0px" bg="transparent" fontSize={(isMobile) ? (isAndroid)? '11px' : '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color="transparent" mr="0.3rem" p="0">{blockNumber}</Text>
        <CircleIcon boxSize={(isMobile) ? '2' : '3'} color="transparent" mr={(isMobile) ? '0px' : '16px'}/>
        </Flex>
        )}
      </Flex>

    </Flex>
    
    </Box>
  );
}

export default Dashboard;
