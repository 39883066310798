import React, { useState, useContext, useEffect } from "react";
import { ethers } from "ethers";
import { Flex, Button, FormControl, Box, FormLabel, Heading, Input, Text, useDisclosure, Image, useToast } from "@chakra-ui/react";
import { SettingsIcon, ArrowDownIcon } from '@chakra-ui/icons';
import { isBrowser, isMobile} from "react-device-detect";
import { isIOS, isAndroid } from "react-device-detect";
import { useWeb3 } from "./hooks/useWeb3";
import { usePAN } from "./hooks/usePAN";
import { useSwap } from "./hooks/useSwap";
import { useIcon } from "./hooks/useIcon";
import { useFactory } from "./hooks/useFactory";

import SwitchSwapTab from "./components/SwitchSwapTab";
import FlexLiquidityButton from "./components/FlexLiquidityButton";
import LiquidityButton from "./components/LiquidityButton";
import SwapTokenSelect from "./components/SwapTokenSelect";
import SwapModal from "./components/SwapModal";
//import SwapSettingModal from "./components/SwapSettingModal";

import logo_cronos from "./assets/cronos_light_bg.png";

import { AppContext } from "./context";

import pairList from "./pairList.json";
import { logoMapping } from './logoMapping';

pairList.forEach((pair) => {
  if (logoMapping[pair.tokenA]) { pair.icon1 = logoMapping[pair.tokenA]; }
  if (logoMapping[pair.tokenB]) { pair.icon2 = logoMapping[pair.tokenB]; }
});

function Swap() {
    const {
        Factory_ADDRESS,
        Factory_ABI,
        uniswapRouterAddress,
        WCRO_address,
        web3Provider,
        isLoading, setIsLoading,
        walletAddress,
        balanceCRO,
        selectedCoin,
        setSelectedCoin,
        selectedSwapTab,
        slippageTolerance,
        setSlippageTolerance,
        tradeType,
        setTradeType,  //0: exact input; 1: exact output
        swapCoin1,
        setSwapCoin1,
        swapCoin2,
        setSwapCoin2,
        swapPath,
        setSwapPath,
        blockNumber,
        color,
        color2
      } = useContext(AppContext);

      const [tokenNumber, setTokenNumber] = useState(1);

      const [amount1,          setAmount1]          = useState("");
      const [amount1Tolerance, setAmount1Tolerance] = useState("");
      const [amount2,          setAmount2]          = useState("");
      const [amount2Tolerance, setAmount2Tolerance] = useState("");
      const [allowed, setAllowed]   = useState(true);
      const [isCoinSwapDisabled, setIsCoinSwapDisabled]   = useState(false);      
      const [balanceSufficient,   setBalanceSufficient]   = useState(true);
      const [liquiditySufficient, setLiquiditySufficient] = useState(true);

      const toast = useToast();

      const { isOpen: isOpen,  onOpen: onOpen,  onClose: onClose  } = useDisclosure();

      const { handleExplorer } = useWeb3();
      const { checkAllowance, approveToken, getTotalSupply, getBalance } = usePAN();
      const { calAmountOUT, calTradePath, TokenSwap, SwapFunc, addLiquidity, removeLiquidity } = useSwap();
      const { CircleIcon } = useIcon();
      const { mint_ptCRO } = useFactory();

      const tabs = ["Swap", "Liquidity"];

      const [pairInfo, setPairInfo] = useState([
        {
          myToken: 0,
          myShare: 0,
          poolTokenA: 0,
          poolTokenB: 0,

        },
        {
          myToken: 0,
          myShare: 0,
          poolTokenA: 0,
          poolTokenB: 0,
        },
        {
          myToken: 0,
          myShare: 0,
          poolTokenA: 0,
          poolTokenB: 0,
        },
        {
          myToken: 0,
          myShare: 0,
          poolTokenA: 0,
          poolTokenB: 0,
        },
        {
          myToken: 0,
          myShare: 0,
          poolTokenA: 0,
          poolTokenB: 0,
        },
      ]);

      const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

      const handleTxCPLT = (desc, text) => {
        toast({
          title: desc + " is completed !",
          description: "Block Number: " + text,
          status: "success",
          duration: 10000,
          isClosable: true,
          containerStyle: {
            width: (isMobile) ? "96vw" : "" ,
            fontSize: (isMobile) ? "14px" : "" ,
          },
        });
      };

      async function coinSwap() {

        setIsCoinSwapDisabled(true);

        var tempCoin1;
        var tempCoin2;
        tempCoin1 = {name: swapCoin1.name, address: swapCoin1.address, symbol: swapCoin1.symbol, decimals: swapCoin1.decimals, chainId: swapCoin1.chainId, icon: swapCoin1.icon};
        tempCoin2 = {name: swapCoin2.name, address: swapCoin2.address, symbol: swapCoin2.symbol, decimals: swapCoin2.decimals, chainId: swapCoin2.chainId, icon: swapCoin2.icon};

        setSwapCoin2({name: tempCoin1.name, address: tempCoin1.address, symbol: tempCoin1.symbol, decimals: tempCoin1.decimals, chainId: tempCoin1.chainId, icon: tempCoin1.icon});
        setSwapCoin1({name: tempCoin2.name, address: tempCoin2.address, symbol: tempCoin2.symbol, decimals: tempCoin2.decimals, chainId: tempCoin2.chainId, icon: tempCoin2.icon});
        
        var tempAmount;
        var tempAmountTolerance;
        var tempTradeType;
        var oriTradeType = tradeType;

        if (oriTradeType===0) { setTradeType(1); tempTradeType=1; tempAmount = amount1; tempAmountTolerance = amount1Tolerance;} //Exact input to exact output
        else                  { setTradeType(0); tempTradeType=0; tempAmount = amount2; tempAmountTolerance = amount2Tolerance;} //Exact output to exact input

        var objOUT;
        if (tempAmount!=="") {
          objOUT = await calAmountOUT(tempAmount.toString(), swapCoin2, swapCoin1, tempTradeType);
        } else {
          objOUT = 
          {
            amount: "",
            amountTolerance: ""
          };
        }

        if (oriTradeType===0) { //setAmount1(objOUT.amount);  setAmount1Tolerance(objOUT.amountTolerance);
                                setAmount2(tempAmount);     setAmount2Tolerance(tempAmountTolerance);    

                              } //Exact input to exact output

        else                  { setAmount1(tempAmount);     setAmount1Tolerance(tempAmountTolerance);
                                //setAmount2(objOUT.amount);  setAmount2Tolerance(objOUT.amountTolerance);

                              } //Exact output to exact input

        await delay(4500);
        setIsCoinSwapDisabled(false);

      }

      const checkStatus = async (coin1Symbol, coin1Addr, amount) => {
        if (web3Provider) {
          try {
            if ((coin1Symbol !== "CRO")&&(amount!=="")) {
              var result = await checkAllowance(coin1Addr, amount, uniswapRouterAddress);
              setAllowed( result ); 
            } else {
              setAllowed( true ); 
            }
          } catch (error) {
            console.error(error);
          }
        }
      };

      const checkTokenAmount = async (amount1, amount2, tradTYPE) => {
        var myBalance;

        if (web3Provider) {
          try {

            if ((tradTYPE===0)&&(amount1!=="")&&(amount2!=="")) {

              if (swapCoin1.symbol !== "CRO") {
                myBalance = await getBalance(swapCoin1.address, walletAddress);
              } else {
                myBalance = balanceCRO;
              }

              if (Number(myBalance)>=Number(amount1)) {
                setBalanceSufficient(true);
              } else {
                setBalanceSufficient(false);
              }
            }

            if ((tradTYPE===1)&&(amount1==="")&&(amount2!=="")) {
              setLiquiditySufficient(false);
            } else {
              setLiquiditySufficient(true);
            }

          } catch (error) {
            console.error(error);
          }
        }
      };

      const updatePairInfo = (i, newMyToken, newMyShare, newPoolTokenA, newPoolTokenB) => {
        setPairInfo((prevPairInfo) => [
          ...prevPairInfo.slice(0, i),
          {
            myToken: newMyToken,
            myShare: newMyShare,
            poolTokenA: newPoolTokenA,
            poolTokenB: newPoolTokenB,
          },
          ...prevPairInfo.slice(i+1), // Keep the rest of the items the same
        ]);
      };

  //regular update states
  useEffect(() => {
    let intervalId;

    const updateStatus = async () => {
      var length = pairList.length;
      var LPtotalsupply;
      var myToken;
      var myShare;
      var poolTokenA;
      var poolTokenB;

      var obj;

      if ((web3Provider)&&(walletAddress!=="")) {
        try {
          for (let i=0; i < length; i++)
          {
            //console.log(pairList[i].liquidityToken_addr, pairList[i].tokenA_addr, pairList[i].tokenB_addr, walletAddress);
            LPtotalsupply = await getTotalSupply(pairList[i].liquidityToken_addr);
            poolTokenA    = await getBalance(pairList[i].tokenA_addr, pairList[i].liquidityToken_addr);
            poolTokenB    = await getBalance(pairList[i].tokenB_addr, pairList[i].liquidityToken_addr);
            myToken       = await getBalance(pairList[i].liquidityToken_addr, walletAddress);
            myShare       = Number(Number(myToken)*100 / Number(LPtotalsupply)).toFixed(2);
            //console.log(LPtotalsupply, poolTokenA, poolTokenB, myToken, myShare);
            updatePairInfo(i, myToken, myShare, poolTokenA, poolTokenB);
          }

          if (tradeType===0) { // EXACT INPUT

            if ( (swapCoin1.name!=="") && (swapCoin2.name!=="") && (Number(amount1)>0) ) {
              obj = await calAmountOUT(amount1, swapCoin1, swapCoin2, 0);
              setAmount2(obj.amount); setAmount2Tolerance(obj.amountTolerance);

              checkTokenAmount(amount1, amount2, 0);
              checkStatus(swapCoin1.symbol, swapCoin1.address, amount1);
            }
            else {
              setAmount2(""); setAmount2Tolerance("");
            }

          }
          else {               // EXACT OUTPUT

            if ( (swapCoin1.name!=="") && (swapCoin2.name!=="") && (Number(amount2)>0) ) {
              obj = await calAmountOUT(amount2, swapCoin1, swapCoin2, 1);
              setAmount1(obj.amount); setAmount1Tolerance(obj.amountTolerance);

              checkTokenAmount(amount1, amount2, 1);
              checkStatus(swapCoin1.symbol, swapCoin1.address, amount1);
            } 
            else {
              setAmount1(""); setAmount1Tolerance("");
            }

          }

        } catch (error) {
          console.error(error);
        }
      }
    };
  
    if (web3Provider) {
      updateStatus();
      intervalId = setInterval(updateStatus, 3000);
    }
  
    return () => clearInterval(intervalId);
  }, [web3Provider, walletAddress, swapCoin1, swapCoin2, amount1, amount2, tradeType]);
  ///////////////////////

  return (
    <Box
    align="center"                                 
    display="flex"                                 
    flexDirection="column"                         
    justifyContent="space-between"                 
    height={(isMobile) ? (web3Provider) ? "88.3vh" : "92.3vh"
                       : "92.3vh"}                                              
    css={{                                         
      overflowY: "scroll",                         
      "&::-webkit-scrollbar": {                    
        width: (isMobile) ? '0px' : '16px',                             
      },                                           
      "&::-webkit-scrollbar-track": {              
        backgroundColor: "transparent",            
      },                                           
      "&::-webkit-scrollbar-thumb": {              
        backgroundColor: "rgba(205, 102, 0, 0.5)", 
        borderRadius: "20px",                      
        border: "4px solid transparent",           
        backgroundClip: "content-box",             
      },                                           
      "&::-webkit-scrollbar-thumb:hover": {        
        backgroundColor: "rgba(205, 102, 0, 0.8)", 
      },                                           
    }} 
    >
    <SwapModal isOpen={isOpen} onClose={onClose} tokenNumber={tokenNumber}/>

    <Box >

      <Flex justifyContent="center" alignItems="center">
        <Box position="relative" top="60px" borderRadius="2rem" bg="orange.200" border="2px" borderColor="orange.200" p="0.4rem">
          <SwitchSwapTab tabs={tabs} onChange={()=>{}} />
        </Box>
      </Flex>
      
      { (selectedSwapTab===0) && //SWAP
      
      <Box
      w={(isMobile) ? "95vw" : "33rem"}
      mx="auto"
      position="relative"
      top={(isMobile) ? "80px" : "150px"}
      boxShadow="rgb(0 0 0 / 8%) 0rem 0.37rem 0.62rem"
      borderRadius="1.25rem">

        <Flex
          alignItems="center"
          p="1rem 1rem 1rem"
          bg="green.50"
          color="rgb(86, 90, 105)"
          justifyContent="space-between"
          borderRadius="1.25rem 1.25rem 0 0">
          <Text
            color="black"
            fontWeight="bold">
            Swap through VVS V2
          </Text>
          <SettingsIcon
            fontSize="1.25rem"
            cursor="pointer"
            _hover={{ color: "rgb(128,128,128)" }}
          />
        </Flex>

        <Box
          p="0rem 0.5rem 0.5rem 0.5rem"
          bg="green.50"
          borderRadius="0 0 0rem 0rem">
          <Flex
            alignItems="center"
            justifyContent="space-between"
            bg="rgb(247, 248, 250)"
            p="1rem 1rem 1rem"
            borderRadius="1.25rem" border="0.06rem solid rgb(237, 238, 242)"
            height="75px"
            _hover={{ border: "0.06rem solid rgb(211,211,211)" }}>
            <Box>
              <Text
                width="70px"
                color="black"
                fontWeight="bold">
                From:
              </Text>
            </Box>
            <Flex
              alignItems="center"
              justifyContent="center"
              bg="green.50"
              p="0.18rem"
              borderRadius="1.25rem"
              pos="absolute"
              top="7.5rem"
              left="50%"
              transform="translateX(-50%)"
              w="2rem">
              <ArrowDownIcon
                bg={isCoinSwapDisabled ? "rgb(220, 220, 220)" : "rgb(247, 248, 250)"}
                _hover={(isCoinSwapDisabled ? {cursor: "default"} : {cursor: "pointer"})}
                color="rgb(128,128,128)"
                h="1.5rem" width="1.62rem"
                borderRadius="1.25rem"
                onClick={((!isCoinSwapDisabled)&&(swapCoin1.name!=="")&&(swapCoin2.name!=="")&&(amount1!=="")&&(amount2!=="")) ? coinSwap : null }
                
              />
            </Flex>
            <Box>
              <Input
                placeholder="0.0"
                fontWeight="500"
                fontSize="1.4rem"
                width="100%"
                size="19rem"
                textAlign="right"
                bg="rgb(247, 248, 250)"
                outline="none"
                border="none"
                focusBorderColor="none"
                borderRadius="0.25rem"
                type="text"
                inputMode="decimal"
                color="black"
                value={amount1}
                style={{ boxShadow: "none" }}
                onChange={ async (e)  => {var objOUT = {amount: "", amountTolerance: ""};
                                          const newAmount = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"); setAmount1(newAmount); setTradeType(0);
                                          if ((swapCoin1.name!=="")&&(swapCoin2.name!=="")&&(Number(newAmount)>0))
                                          { objOUT = await calAmountOUT(newAmount, swapCoin1, swapCoin2, 0); setAmount2(objOUT.amount); setAmount2Tolerance(objOUT.amountTolerance);
                                            setSwapPath(objOUT.path);
                                          } 
                                          if (newAmount==="") { setAmount2(""); }
                                          checkTokenAmount(newAmount, objOUT.amount, tradeType);
                                          checkStatus(swapCoin1.symbol, swapCoin1.address, newAmount);
                                         }}
              />
            </Box>
            <Box ml="0.5rem">
            <SwapTokenSelect openTokenModal={onOpen} setTokenNumber={setTokenNumber} tokenNumber={1} value={swapCoin1.symbol} image={swapCoin1.icon}/>
            </Box>
          </Flex>

          <Flex
            alignItems="center"
            justifyContent="space-between"
            bg="rgb(247, 248, 250)"
            p="1rem 1rem 1rem"
            mt="0.25rem"
            borderRadius="1.25rem" border="0.06rem solid rgb(237, 238, 242)"
            height="75px"
            _hover={{ border: "0.06rem solid rgb(211,211,211)" }}
            >
            <Box>
              <Text
                width="70px"
                color="black"
                fontWeight="bold">
                To:
              </Text>
            </Box>
            <Box>
              <Input
                placeholder="0.0"
                fontWeight="500"
                fontSize="1.4rem"
                width="100%"
                size="19rem"
                textAlign="right"
                bg="rgb(247, 248, 250)"
                outline="none"
                border="none"
                focusBorderColor="none"
                borderRadius="0.25rem"
                type="text"
                inputMode="decimal"
                color="black"
                value={amount2}
                style={{ boxShadow: "none" }}
                onChange={ async (e)  => {var objOUT = {amount: "", amountTolerance: ""};
                                          const newAmount = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"); setAmount2(newAmount); setTradeType(1);
                                          if ((swapCoin1.name!=="")&&(swapCoin2.name!=="")&&(Number(newAmount)>0))
                                          { objOUT = await calAmountOUT(newAmount, swapCoin1, swapCoin2, 1); setAmount1(objOUT.amount); setAmount1Tolerance(objOUT.amountTolerance);
                                            setSwapPath(objOUT.path);
                                          } 
                                          if (newAmount==="") { setAmount1(""); }
                                          checkTokenAmount(objOUT.amount, newAmount, tradeType);
                                          checkStatus(swapCoin1.symbol, swapCoin1.address, newAmount);
                                         }}
              />
            </Box>
            <Box ml="0.5rem">
            <SwapTokenSelect openTokenModal={onOpen} setTokenNumber={setTokenNumber} tokenNumber={2} value={swapCoin2.symbol} image={swapCoin2.icon}/>
            </Box>
          </Flex>

        </Box>

        { (liquiditySufficient===true) && (balanceSufficient===false) &&         <LiquidityButton onClick={ async () => {} }
                                                                                                  isLoading={isLoading} isDisable={true} text="Insufficient Balance" allowed={false} /> }
        { (liquiditySufficient===false) &&                                       <LiquidityButton onClick={ async () => {} }
                                                                                                  isLoading={isLoading} isDisable={true} text="Insufficient Liquidity" allowed={false} /> }

        { ((balanceSufficient===true)&&(liquiditySufficient===true)&&(allowed===false)) && <LiquidityButton onClick={ async () => { var tx = await approveToken(swapCoin1.address, uniswapRouterAddress, amount1);
                                                                                                                                   if (typeof tx !== 'undefined') {
                                                                                                                                   setIsLoading(true);
                                                                                                                                   var receipt = await tx.wait(); //await tx
                                                                                                                                   if (receipt.status === 0) { console.log("tx failed"); }
                                                                                                                                   if (receipt.status === 1) { handleTxCPLT("ApproveToken", receipt.blockNumber); }
                                                                                                                                   setIsLoading(false);
                                                                                                                                   checkStatus(swapCoin1.symbol, swapCoin1.address, amount1);
                                                                                                                                  }
                                                                                                                    }}
                                                                                                           isLoading={isLoading} isDisable={false} text={"Approve " + swapCoin1.symbol} allowed={false} /> }

        { ((balanceSufficient===true)&&(liquiditySufficient===true)&&(allowed===true)) && <LiquidityButton onClick={ async () => { var tx = await SwapFunc(amount1, amount1Tolerance, amount2, amount2Tolerance, swapPath, tradeType);
                                                                                                                                   if (typeof tx !== 'undefined') {
                                                                                                                                   setIsLoading(true);
                                                                                                                                   var receipt = await tx.wait(); //await tx
                                                                                                                                   if (receipt.status === 0) { console.log("tx failed"); }
                                                                                                                                   if (receipt.status === 1) { handleTxCPLT("Swap", receipt.blockNumber); }
                                                                                                                                   setIsLoading(false); }
                                                                                                                    }}
                                                                                                           isLoading={isLoading}
                                                                                                           isDisable={((swapCoin1.name==="")||(swapCoin2.name==="")||(amount1==="")||(amount2===""))}
                                                                                                           text="Swap" allowed={true} /> }

      </Box>

    }

    { (selectedSwapTab===1) && //LIQUIDITY
      <Box 
        w="60rem"
        mx="auto"
        position="relative"
        top={ (isMobile) ? '80px' : '150px' }
        boxShadow="rgb(0 0 0 / 8%) 0rem 0.37rem 0.62rem"
        borderRadius="1.25rem"
      >
      {pairList.map((val, index) => (
              <FlexLiquidityButton key={val.name} pair={val} isFirst={(index===0) ? true : false} isLast={(index===(pairList.length-1)) ? true : false} pid={0}
                                   contractAddress={val.liquidityToken_addr} myToken={pairInfo[index].myToken} myShare={pairInfo[index].myShare}
                                   poolTokenA={pairInfo[index].poolTokenA} poolTokenB={pairInfo[index].poolTokenB}/>
            ))}
      
      </Box>
    }

    </Box>

      <Flex
        alignItems="center"
        justifyContent="space-evenly">
        <Text mt="10rem" fontSize='35px' fontWeight="bold" fontFamily="Arial">
        </Text>
      </Flex>

      <Flex bottom="0px" justify="space-between" alignItems="center" width="99vw" mb={(isAndroid) ? "0.7rem" : "0.2rem"}>
        
        <Flex mr="16px" w="90px">
        </Flex>

        <Flex alignItems="center">
          <Text fontSize={(isMobile) ? '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color="white" mr="0.4rem" >powered by</Text>
          <Box width="85px" height="30px">
            <Image src={logo_cronos} fit="contain"/>
          </Box>
        </Flex>

        <Flex alignItems="center" w={(web3Provider) ? "" : "90px"} >
          {web3Provider && (
          <Flex alignItems="center">
          <Button h="0px" bg="transparent" fontSize={(isMobile) ? (isAndroid)? '11px' : '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color="white" mr="0.3rem" p="0" onClick={() => handleExplorer( ("/block/" + blockNumber) )}>{blockNumber}</Button>
          <CircleIcon boxSize={3} color="rgb(55, 160, 105)" mr="0px"/>
          </Flex>
          )}
          {!web3Provider && (
          <Flex alignItems="center">
          <Text h="0px" bg="transparent" fontSize={(isMobile) ? (isAndroid)? '11px' : '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color="transparent" mr="0.3rem" p="0">{blockNumber}</Text>
          <CircleIcon boxSize={3} color="transparent" mr="0px"/>
          </Flex>
          )}
        </Flex>
      </Flex>
    
    </Box>
  );
  
}

export default Swap;
