import React, { useState, useContext, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { ChakraProvider, Box, Flex, Text, Button, IconButton, Spacer, Tooltip, Image, Circle, useDisclosure, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { CopyIcon, SettingsIcon, InfoIcon, InfoOutlineIcon, HamburgerIcon } from '@chakra-ui/icons';
import { isBrowser, isMobile} from "react-device-detect";
import { isIOS, isAndroid } from "react-device-detect";         
import { ethers } from "ethers";
import { theme } from './theme';
import Home from "./Home";
import Mint from "./Mint";
import Burn from "./Burn";
import Claim from "./Claim";
import Swap from "./Swap";
import PAN from "./PAN";
import NFT from "./NFT";
import WalletModal from "./components/WalletModal";
import NFTDetail from "./components/NFTDetail";

import Dashboard from "./Dashboard";
import { useWeb3 } from "./hooks/useWeb3";
import { usePAN } from "./hooks/usePAN";
import { useFactory } from "./hooks/useFactory";
import { useNFT } from "./hooks/useNFT";
import { AppContext } from "./context";
import logo from "./assets/creampan.png";
import logo_cro from "./assets/CRO.png";
import logo_cronos from "./assets/cronos_light_bg.png";

function App() {
  const {
    chainId,
    WCRO_address,
    ptCRO_ABI,
    balanceCRO,
    blockNumber,
    web3Provider,
    walletChainId,
    walletAddress,
    ptCROV2_ADDRESS,
    ytCROV2_ADDRESS,
    setPricePTCRO,
    setPriceYTCRO,
    setPricePTCROV2,
    setPriceYTCROV2,
    setPricePAN,
    setBalanceCRO,
    setBalanceWCRO,
    setWalletAddress,
    setBlockNumber,
    setColor,
    setColor2,
    isWalletOpen,
    setIsWalletOpen,
    cronos_nodeUrl
  } = useContext(AppContext);

  const { disconnectWeb3 } = useWeb3();
  const { getUtilization, getAccountBalance, getHodlLVLDiscnt } = useFactory();
  const { getTokenPrice, getCROAPRFromServer, getPTCROPrice, getYTCROPrice, getPANPrice } = usePAN();
  const { getUserInfo, getTotalAdditionalAmount} = useNFT();


  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCopied, setIsCopied] = useState(false);

  async function getCROBalance() {
    //const currentAddress = await web3Provider.getSigner().getAddress();
    const provider = new ethers.providers.JsonRpcProvider(cronos_nodeUrl);
    //const signer = web3Provider.getSigner();

    var Balance = await web3Provider.getBalance(walletAddress);

    const WCRO_contract = new ethers.Contract(WCRO_address, ptCRO_ABI, provider); //use ptCRO_ABI for ERC20 balanceOf query.
    var balanceWCRO = await WCRO_contract.balanceOf(walletAddress);

    setBalanceCRO(Number(ethers.utils.formatEther(Balance)).toFixed(3));
    setBalanceWCRO(Number(ethers.utils.formatEther(balanceWCRO)).toFixed(3));
    //setWalletAddress(currentAddress);
  }

  async function getBlockNumber() {
    // Get the current block number
    let newNumber = await web3Provider.getBlockNumber();
    //console.log(Number(newNumber), Number(blockNumber));
    if (Number(newNumber) !== Number(blockNumber)) {
      setColor('rgb(65, 170, 115)');
      setColor2('rgb(65, 170, 115)');
    }
    setBlockNumber(Number(newNumber));
  }

  async function _getTokenPrice() {
    var price_ptCRO = await getPTCROPrice();
    var price_ytCRO = await getYTCROPrice();
    var price_PAN   = await getPANPrice();
    //V2
    var price_ptCROv2 = await getTokenPrice(ptCROV2_ADDRESS);
    var price_ytCROv2 = await getTokenPrice(ytCROV2_ADDRESS);
    //

    //console.log(price_ptCRO, price_ytCRO, price_PAN);
    //console.log(price_ptCROv2, price_ytCROv2);

    setPricePTCRO(price_ptCRO);
    setPriceYTCRO(price_ytCRO);
    setPricePAN(price_PAN);
    //V2
    setPricePTCROV2(price_ptCROv2);
    setPriceYTCROV2(price_ytCROv2);
    //

  }

  //regular update states
  useEffect(() => {
    let intervalId;
    
    const updateBalance = async () => {
      if (web3Provider) {
        //console.log(web3Provider, chainId);
        try {
          if (walletAddress !== "") {
            await _getTokenPrice();
            await getAccountBalance();
            await getCROBalance();
            await getUtilization();
            await getHodlLVLDiscnt();
            await getBlockNumber();
            await getCROAPRFromServer();
            //V2
            await getUserInfo();
            await getTotalAdditionalAmount();
            //


            setTimeout(() => {setColor('rgb(55, 160, 105)'); setColor2('rgb(255, 255, 255)');}, 1000);
          }
          else {
            var currentAddress = await web3Provider.getSigner().getAddress();
            //console.log(currentAddress);
            setWalletAddress(currentAddress);
          }

        } catch (error) {
          console.error(error);
        }

      }
    };
    
    //console.log(Number(blockNumber));
    if (web3Provider) {
      updateBalance();
      intervalId = setInterval(updateBalance, 6000);
    }
    else {
      disconnectWeb3();
    }
  
    return () => clearInterval(intervalId);
  }, [web3Provider, blockNumber, walletAddress]);
  ///////////////////////

  const handleCopyClick = () => {
    navigator.clipboard.writeText(walletAddress);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  };

  function handleInfoClick() {
    window.open("https://creampan.gitbook.io/creampan-finance/", "_blank");
  }

  return (
    <ChakraProvider theme={theme}>
      
          <Flex
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            padding={(isMobile) ? "0.2rem" : "0.7rem" }
            bg="black.000"
            color="white"
            w="100vw"
          >
            <WalletModal isOpen={isWalletOpen} onClose={()=>{setIsWalletOpen(false)}} />

            { (!isMobile) &&
            (<Flex alignItems="center" align="center">
              <Link to="/">
                <Image src={logo} boxSize="4vw" height="5vh" fit="contain" mr={1}/>
              </Link>
              <Link to="/">
                  <Text fontSize="28px" fontWeight="bold" display="flex" alignItems="center">
                    Creampan
                  </Text>
              </Link>
             <Link to="/dashboard">
                <Button variant="ghost" size="lg" color="white" ml={0.5}>
                  Dashboard
                </Button>
              </Link>
              <Link to="/mint">
                <Button variant="ghost" size="lg" color="white" ml={0.5}>
                  Mint
                </Button>
              </Link>
              <Link to="/burn">
                <Button variant="ghost" size="lg" color="white" ml={0.5}>
                  Burn
                </Button>
              </Link>
              <Link to="/claim">
                <Button variant="ghost" size="lg" color="white" ml={0.5}>
                  Claim
                </Button>
              </Link>
              <Link to="/swap">
                <Button variant="ghost" size="lg" color="white" ml={0.5}>
                  Swap
                </Button>
              </Link>
              <Link to="/PAN">
                <Button variant="ghost" size="lg" color="white" ml={0.5}>
                  PAN
                </Button>
              </Link>
              <Link to="/NFT">
                <Button variant="ghost" size="lg" color="white" ml={0.5}>
                  NFT
                </Button>
              </Link>

            </Flex>
            )}

            { (!isMobile) &&
            (<Flex alignItems="center">
              {web3Provider && (
                  <Flex alignItems="center" align="center">
                      <Text color="#007AFF" variant="link" fontSize="lg" mr={1} fontWeight="bold">
                        Connected Address: {walletAddress.substring(0,7)}...{walletAddress.substring((walletAddress.length-5),(walletAddress.length))}
                      </Text>
                      <Tooltip label={isCopied ? "Copied!" : "Copy address"} closeOnClick={false}>
                      <IconButton
                        aria-label="Copy Address"
                        borderRadius="1.25rem"
                        icon={<CopyIcon />}
                        bg="transparent"
                        color="gray"
                        _hover={{ bg: "rgb(200,200,200)" }}
                        size="sm"
                        mr={1}
                        onClick={handleCopyClick}
                        />
                      </Tooltip>

                    <Flex>
                      { ( Number(walletChainId) === Number(chainId) ) && (
                        <Text bg="rgb(220,220,220)" color="#0050FF" variant="link" fontSize='16px' mr={1} fontWeight="bold" borderRadius="1.0rem" border="0px" p="0.5rem" >{Number(balanceCRO).toFixed(2)} CRO</Text>
                      )}
                      { ( Number(walletChainId) !== Number(chainId) ) && (
                        <Text bg="rgb(220,220,220)" color="red.500" variant="link" fontSize='16px' mr={1} fontWeight="bold" borderRadius="1.0rem" border="0px" p="0.5rem" >Wrong Network!!</Text>
                      )}
                      <Button borderRadius="1.25rem" bg="rgb(220,220,220)" color="#007AFF" onClick={disconnectWeb3}>
                        Disconnect
                      </Button>
                    </Flex>

                  </Flex>
              )}
            
              {!web3Provider && (
                  <Flex>
                  <Button borderRadius="1.25rem" bg="orange" border="2px" borderColor="orange" color="white" _hover={{ color: "white", bg: "orange", borderColor: "white" }}
                          onClick={ () => {setIsWalletOpen(true)} }>
                    Connect Wallet
                  </Button>
                  </Flex>
              )}

                <IconButton
                  icon={<InfoOutlineIcon />}
                  aria-label="Infos"
                  bg="transparent"
                  color="orange"
                  borderRadius="1.5rem"
                  _hover={{ bg: "orange.400" }}
                  _active={{ bg: "transparent" }}
                  size=""
                  ml="1rem"
                  mr="0.3rem"
                  onClick={handleInfoClick}
                />
             </Flex>
            )}

            { (isMobile) &&
            ( <Box w="100vw">
              <Flex align="center" justify="space-between" wrap="wrap">
                  <Flex>
                    <Menu>
                      <MenuButton as={IconButton} aria-label='Options' icon={<HamburgerIcon />} variant='outline' bg="orange"/>
                      <MenuList bg="black" className="menu-list">
                        <Link to="/dashboard">
                        <MenuItem bg="black" color="white">Dashboard</MenuItem>
                        </Link>
                        <Link to="/mint">
                          <MenuItem bg="black" color="white">Mint</MenuItem>
                        </Link>
                        <Link to="/burn">
                          <MenuItem bg="black" color="white">Burn</MenuItem>
                        </Link>
                        <Link to="/claim">
                          <MenuItem bg="black" color="white">Claim</MenuItem>
                        </Link>
                        <Link to="/swap">
                          <MenuItem bg="black" color="white">Swap</MenuItem>
                        </Link>
                        <Link to="/PAN">
                          <MenuItem bg="black" color="white">PAN</MenuItem>
                        </Link>
                        <Link to="/NFT">
                          <MenuItem bg="black" color="white">NFT</MenuItem>
                        </Link>
                        <MenuItem bg="black" color="white" onClick={handleInfoClick}>Doc</MenuItem>
                      </MenuList>
                    </Menu>

                    <Link to="/">
                      <Text fontSize="24px" fontWeight="bold" display="flex" alignItems="center"  ml={3}>
                        Creampan
                      </Text>
                    </Link>
                  </Flex>

                  {!web3Provider && (
                  <Flex alignItems="center" align="center">
                  <Button borderRadius="1.25rem" bg="orange" border="2px" borderColor="orange" color="white" _hover={{ color: "white", bg: "orange", borderColor: "white" }}
                          onClick={ () => {setIsWalletOpen(true)} }>
                    Connect Wallet
                  </Button>
                  </Flex>
                  )}

                  { web3Provider &&
                    (<Flex alignItems="center" align="center">
                      { ( Number(walletChainId) === Number(chainId) ) && (
                        <Text bg="rgb(220,220,220)" color="#0050FF" variant="link" fontSize="sm" mr={1} fontWeight="bold" borderRadius="1.0rem" border="0px" p="0.5rem" >{Number(balanceCRO).toFixed(0)} CRO</Text>
                      )}
                      { ( Number(walletChainId) !== Number(chainId) ) && (
                        <Text bg="rgb(220,220,220)" color="red.500" variant="link" fontSize="sm" mr={1} fontWeight="bold" borderRadius="1.0rem" border="0px" p="0.5rem" >Wrong Network!!</Text>
                      )}
                      <Button borderRadius="1.25rem" bg="rgb(220,220,220)" color="#007AFF" fontSize="sm" onClick={disconnectWeb3}>
                        Disconnect
                      </Button>
                    </Flex>
                  )}

              </Flex>

              {web3Provider && (
                  <Flex alignItems="center" align="center">
                      <Text color="#007AFF" variant="link" fontSize={(isAndroid) ? "9px" : "10px"} ml={3} fontWeight="bold">
                        Connected Address: {walletAddress.substring(0,7)}...{walletAddress.substring((walletAddress.length-5),(walletAddress.length))}
                      </Text>
                      <Tooltip label={isCopied ? "Copied!" : "Copy address"} closeOnClick={false}>
                      <IconButton
                        aria-label="Copy Address"
                        borderRadius="1.25rem"
                        icon={<CopyIcon />}
                        bg="transparent"
                        color="gray"
                        _hover={{ bg: "rgb(200,200,200)" }}
                        size="sm"
                        ml={1}
                        onClick={handleCopyClick}
                        />
                      </Tooltip>
                  </Flex>
              )}              

              </Box>
            )}

          </Flex>

          <Routes>
          <Route path="/" element={<Home />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/mint" element={<Mint />} />
            <Route path="/burn" element={<Burn />} />
            <Route path="/claim" element={<Claim />} />
            <Route path="/swap" element={<Swap />} />
            <Route path="/PAN" element={<PAN />} />
            <Route path="/NFT" element={<NFT />} />
            <Route path="/NFT/:tokenAddress/:tokenId" element={<NFTDetail />} />
          </Routes>

    </ChakraProvider>
  );
}

export default App;