import { Text } from "@chakra-ui/react";
import React, { useState, useEffect } from 'react';
import { Box } from "@chakra-ui/react";
import { Line } from 'react-chartjs-2';

const InteractiveLineChart = ({ data, utilization } ) => {
  const [mouseEnter, setMouseEnter] = useState(0);

  const updateTooltip = (defaultDataIndex, defaultDatasetIndex = 0) => {
    try {
      const tooltipEl = document.getElementById('chart-tooltip');
      const tooltipE2 = document.getElementById('chart-tooltip2');
      const tooltipE3 = document.getElementById('chart-cursor');
    
      const dataset = data.datasets[defaultDatasetIndex];
      const yValue = dataset.data[defaultDataIndex];
      const xValue = data.labels[defaultDataIndex];
  
      const pixelx_cal = Number((5.05 * defaultDataIndex) + 1).toFixed(0);
      const pixely_cal = Number( 73 - (0.4 * defaultDataIndex)).toFixed(0);
  
      tooltipEl.innerHTML = Number(yValue).toFixed(2) + "%";
      tooltipEl.style.left = pixelx_cal + 'px';
      tooltipEl.style.top = pixely_cal + 'px';
  
      tooltipE2.innerHTML = xValue + "%";
      tooltipE2.style.left = pixelx_cal + 'px';
      tooltipE2.style.top = '0px';
  
      tooltipE3.style.left = pixelx_cal + 'px';

      tooltipEl.style.display = 'block';
      tooltipE2.style.display = 'block';
      tooltipE3.style.display = 'block';
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    //console.log("mouseEnter value is now: ", mouseEnter);
    if (mouseEnter === 0) {
      updateTooltip(utilization);
    }
  }, [mouseEnter, utilization]);

  const handleMouseOver = () => {
    setMouseEnter(1);
  };

  const handleMouseLeave = () => {
    setMouseEnter(0);
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      x: {
        display: false,
        grid: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          font: {
            size: 12,
          },
        },
      },
      y: {
        min: -5,
        max: 30,
        display: false,
        grid: {
          drawBorder: false,
          display: true,
          color: 'rgba(0, 0, 0, 0.1)',
          borderDash: [4, 4],
        },
        ticks: {
          font: {
            size: 12,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: (context) => {

          if (mouseEnter===1) {
          const tooltipEl = document.getElementById('chart-tooltip');
          const tooltipE2 = document.getElementById('chart-tooltip2');
          const tooltipE3 = document.getElementById('chart-cursor');

          const { dataIndex, datasetIndex } = context.tooltip.dataPoints[0];
          const dataset = data.datasets[datasetIndex];
          const yValue = dataset.data[dataIndex];
          const xValue = data.labels[dataIndex];
  
            tooltipEl.innerHTML = Number(yValue).toFixed(2) + "%";
            tooltipEl.style.left = (context.tooltip.caretX+5) + 'px';
            tooltipEl.style.top = context.tooltip.caretY + 'px';
            
            //console.log(context.tooltip.caretX, context.tooltip.caretY);
            tooltipE2.innerHTML = xValue + "%";
            tooltipE2.style.left = (context.tooltip.caretX+5) + 'px';
            tooltipE2.style.top = '0px';

            tooltipE3.style.left = (context.tooltip.caretX-1) + 'px';

            tooltipEl.style.display = 'block';
            tooltipE2.style.display = 'block';
            tooltipE3.style.display = 'block';
            }
            
            },
        },
    },};


  return (
    <Box style={{ position: 'relative' }} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
      <Line 
        data={data} 
        options={chartOptions} 
      />
      {/* Conditionally display tooltips based on isHovering state */}
      <Box id="chart-tooltip"  style={{ position: 'absolute', display: 'none', backgroundColor: 'white', color: 'rgb(0, 123, 255)', padding: '3px', borderRadius: '6px', fontWeight: 'bold' }}></Box>
      <Box id="chart-tooltip2" style={{ position: 'absolute', display: 'none', backgroundColor: 'white', color: 'rgb(220, 53, 69)', padding: '3px', borderRadius: '6px', fontWeight: 'bold' }}></Box>
      <Box id="chart-cursor"   style={{ position: 'absolute', width: '2px', top: '0px', height: '100%', backgroundColor: 'lightgray', display: 'none' }}></Box>
    </Box>
  );
};

export default InteractiveLineChart;
