import { React, useContext } from "react";
import { Button, Spinner, useDisclosure, SimpleGrid, Box, Image, Text, Badge, Flex, Center} from "@chakra-ui/react";
import { isBrowser, isMobile} from "react-device-detect"; 
import { useNavigate  } from "react-router-dom";
import { AppContext } from "../context";
import { useWeb3 } from "../hooks/useWeb3";

export default function NFTGallery({ nfts }) {
  const {
    web3Provider,
    amount,
    isWalletOpen,
    setIsWalletOpen
  } = useContext(AppContext);

  const { connectWeb3 } = useWeb3();
  const       navigate  = useNavigate();

  return web3Provider ? (
    isMobile ? (
      <SimpleGrid columns={[2, 2, 3, 6]} spacingX="50px" spacingY="20px" p="20px">
        {nfts.map(nft => (
          <Box key={nft.id} boxShadow="md" p="0px" border="2px" borderColor="green.600" borderRadius="1rem 1rem 1rem 1rem" w="150px" bg="rgb(170,208,180)" onClick={() => navigate(`/NFT/${nft.tokenAddress}/${nft.tokenId}`)}
          _hover={ {filter: `brightness(90%)`, borderColor:"orange", cursor: "pointer"} } 
          cursor="pointer"
          >
    
            <Image src={nft.tokenImage} alt={nft.name} boxSize="150px" objectFit="cover" borderRadius="1rem 1rem 0rem 0rem"/>
    
            <Flex justifyContent="center" alignItems="center">
              <Text mt="5px" mb="5px" fontSize="1.0rem" fontWeight="normal" lineHeight="short">
                {nft.name}
              </Text>
              <Text mt="5px" mb="5px" fontSize="1.1rem" fontWeight="normal"> &nbsp;#{nft.tokenId} </Text>
            </Flex>

            <Flex justifyContent="space-evenly" alignItems="center" mb="10px">
              <Badge borderRadius="full" fontSize='0.6em' px="2" colorScheme="teal" backgroundColor="rgb(206,225,246)">
                Level {nft.level}
              </Badge>
              <Badge borderRadius="full" fontSize='0.6em' px="2" colorScheme="teal" backgroundColor="rgb(206,225,246)">
                Power {nft.power}
              </Badge>
            </Flex>

          </Box>
        ))}
      </SimpleGrid>
    ) : (
      <SimpleGrid columns={[1, 2, 3, 6]} spacingX="50px" spacingY="30px" p="20px">
        {nfts.map(nft => (
          <Box key={nft.id} boxShadow="md" p="0px" border="2px" borderColor="green.600" borderRadius="1rem 1rem 1rem 1rem" w="200px" bg="rgb(170,208,180)" onClick={() => navigate(`/NFT/${nft.tokenAddress}/${nft.tokenId}`)}
          _hover={ {filter: `brightness(90%)`, borderColor:"orange", cursor: "pointer"} } 
          cursor="pointer"
          >
    
            <Image src={nft.tokenImage} alt={nft.name} boxSize="200px" objectFit="cover" borderRadius="1rem 1rem 0rem 0rem"/>
    
            <Flex justifyContent="center" alignItems="center">
              <Text mt="5px" mb="5px" fontSize="1.3rem" fontWeight="normal" lineHeight="short">
                {nft.name}
              </Text>
              <Text mt="5px" mb="5px" fontSize="1.4rem" fontWeight="normal"> &nbsp;#{nft.tokenId} </Text>
            </Flex>

            <Flex justifyContent="space-evenly" alignItems="center" mb="10px">
              <Badge borderRadius="full" fontSize='0.8em' px="2" colorScheme="teal" backgroundColor="rgb(206,225,246)">
                Level {nft.level}
              </Badge>
              <Badge borderRadius="full" fontSize='0.8em' px="2" colorScheme="teal" backgroundColor="rgb(206,225,246)">
                Power {nft.power}
              </Badge>
            </Flex>

          </Box>
        ))}
      </SimpleGrid>      
    )

  ) : (
    <Box p="0.5rem" bg="white" borderRadius="1.25rem 1.25rem 1.25rem 1.25rem">
      <Button
        color="white"
        bg="orange"
        width="100%"
        p="1.62rem"
        borderRadius="1.25rem"
        _hover={{ bg: "orange.400" }}
        onClick={ () => {setIsWalletOpen(true)} }
      >
        Connect Wallet
      </Button>
    </Box>
  );
}
