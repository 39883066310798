import { useState, useEffect, useContext } from "react";
import { ethers } from "ethers";
import { ChainId, Fetcher, Token, Route, Trade, TokenAmount, TradeType, Percent } from '@uniswap/sdk';
import { AppContext } from "../context";
import axios from 'axios';
import { usePAN } from "./usePAN";

export function useSwap() {
  const {
    MasterBaker_ADDRESS,
    MasterBaker_ABI,
    ptCRO_ADDRESS,
    ytCRO_ADDRESS,
    WCRO_address,
    PAN_address,
    walletAddress,
    uniswapRouterAddress,
    web3Provider,
    cronos_nodeUrl,
    price_ptCRO,
    price_ytCRO,
    price_PAN,
    setPricePTCRO,
    setPriceYTCRO,
    setPricePAN, 
    selectedTab,
    setSelectedTab,
    setAprCRO,
    setStakedCRO,
    setUnstakingCRO,
    slippageTolerance,
    multiHops,
    setSlippageTolerance,
    swapCoin1,
    setSwapCoin1,
    swapCoin2,
    setSwapCoin2,
  } = useContext(AppContext);

  const ChainID = 338;

  const { BigNumber } = require('ethers');
  const { checkAllowance, approveToken, getTotalSupply, getBalance } = usePAN();

  const WCRO_ABI              = require("../abis/WCRO_abi.json");
  const UniswapV2Pair_ABI     = require("../abis/UniswapV2Pair_abi.json");
  const UniswapV2ERC20_ABI    = require("../abis/UniswapV2ERC20_abi.json");
  const UniswapV2Router02_ABI = require("../abis/UniswapV2Router02_abi.json");

    async function calAmountOUT(amount, inputToken, outputToken, tradeType) { //Input unit: ether;
      var obj = 
      {
        amount: "",
        amountTolerance: "",
        path: []
      };

      if ((inputToken.symbol === "CRO")||(outputToken.symbol === "CRO")) {  // rule for CRO
        obj.amount = amount;
        obj.amountTolerance = amount;
        
        return (obj);
      }

      try {

        var tradePath

        if (tradeType===0) {  // EXACT INPUT
          tradePath = await calTradePath(amount, 0, tradeType);

          obj.amount = tradePath.amount;
          obj.amountTolerance = tradePath.amountTolerance;
          obj.path = tradePath.path;
          //console.log(obj);

          return (obj);
        }
        else {                // EXACT OUTPUT
          tradePath = await calTradePath(0, amount, tradeType);

          obj.amount = tradePath.amount;
          obj.amountTolerance = tradePath.amountTolerance;
          obj.path = tradePath.path;
          //console.log(obj);
  
          return (obj);
        }    
      } catch (error) {

        if (error.toString()==="InsufficientReservesError") {
          console.log("Insufficient reserves in the liquidity pool.");
        } else {
          console.log('calAmountOUT error occurred:', error);
        }

        return (obj);
      }

    }

    async function calLiquidity(pair, amountDesired, inputToken) { //Input unit: ether;
      const tokenA = new Token(ChainId.TESTNET, pair.tokenA_addr,pair.tokenA_decimals);
      const tokenB = new Token(ChainId.TESTNET, pair.tokenB_addr,pair.tokenB_decimals);

      const tokenPair = await Fetcher.fetchPairData(tokenA, tokenB, web3Provider);

      const reserve0 = tokenPair.reserve0.toFixed(pair.tokenA_decimals);
      const reserve1 = tokenPair.reserve1.toFixed(pair.tokenB_decimals);
      const reserve0_BigN = BigNumber.from(ethers.utils.parseEther(reserve0));
      const reserve1_BigN = BigNumber.from(ethers.utils.parseEther(reserve1));

      if (inputToken === "A") {
        var myTokenA_BigN = BigNumber.from(ethers.utils.parseEther(amountDesired));
        console.log(myTokenA_BigN.toString());

        var amountBDesired;
        var amountB_ether;
        if (tokenPair.token0.address === pair.tokenA_addr) {
          amountBDesired = myTokenA_BigN.mul(reserve1_BigN).div(reserve0_BigN).toString();
          amountB_ether = Number(ethers.utils.formatEther(amountBDesired)).toFixed(8);
          //console.log(tokenPair.token0.address, pair.tokenA_addr, 1);
          //console.log(amountBDesired, amountB_ether);
        }
        else {
          amountBDesired = myTokenA_BigN.mul(reserve0_BigN).div(reserve1_BigN).toString();
          amountB_ether = Number(ethers.utils.formatEther(amountBDesired)).toFixed(8);
          //console.log(tokenPair.token0.address, pair.tokenA_addr, 2);
          //console.log(amountBDesired, amountB_ether);
        }

        return (amountB_ether);
      }
      else if (inputToken === "B") {
        var myTokenB_BigN = BigNumber.from(ethers.utils.parseEther(amountDesired));
        console.log(myTokenB_BigN.toString());

        var amountADesired;
        var amountA_ether;
        if (tokenPair.token0.address === pair.tokenB_addr) {
          amountADesired = myTokenB_BigN.mul(reserve1_BigN).div(reserve0_BigN).toString();
          amountA_ether = Number(ethers.utils.formatEther(amountADesired)).toFixed(8);
          //console.log(tokenPair.token0.address, pair.tokenA_addr, 1);
          //console.log(amountBDesired, amountB_ether);
        }
        else {
          amountADesired = myTokenB_BigN.mul(reserve0_BigN).div(reserve1_BigN).toString();
          amountA_ether = Number(ethers.utils.formatEther(amountADesired)).toFixed(8);
          //console.log(tokenPair.token0.address, pair.tokenA_addr, 2);
          //console.log(amountBDesired, amountB_ether);
        }

        return (amountA_ether);        
      }

    }

    async function calLPShare(pair, amountLP) { //Input unit: ether;
      var obj = 
      {
        amountA: 0,
        amountB: 0
      }

      const tokenA = new Token(ChainId.TESTNET, pair.tokenA_addr, pair.tokenA_decimals);
      const tokenB = new Token(ChainId.TESTNET, pair.tokenB_addr,pair.tokenB_decimals);
      const tokenPair = await Fetcher.fetchPairData(tokenA, tokenB, web3Provider);

      const totalLiquidity = await getTotalSupply(tokenPair.liquidityToken.address);
      const reserve0       = tokenPair.reserve0.toFixed(pair.tokenA_decimals);
      const reserve1       = tokenPair.reserve1.toFixed(pair.tokenB_decimals);
      const reserve0_BigN  = BigNumber.from(ethers.utils.parseEther(reserve0));
      const reserve1_BigN  = BigNumber.from(ethers.utils.parseEther(reserve1));

      var userLiquidity_BigN  = BigNumber.from(ethers.utils.parseEther(amountLP));
      var totalLiquidity_BigN = BigNumber.from(ethers.utils.parseEther(totalLiquidity));

      var amountA_BigN;
      var amountB_BigN;
      if (tokenPair.token0.address === pair.tokenA_addr) {
        amountA_BigN = reserve0_BigN.mul(userLiquidity_BigN).div(totalLiquidity_BigN);
        amountB_BigN = reserve1_BigN.mul(userLiquidity_BigN).div(totalLiquidity_BigN);        
      }
      else {
        amountA_BigN = reserve1_BigN.mul(userLiquidity_BigN).div(totalLiquidity_BigN);
        amountB_BigN = reserve0_BigN.mul(userLiquidity_BigN).div(totalLiquidity_BigN);
      }
      
      obj.amountA       = Number(ethers.utils.formatEther(amountA_BigN.toString())).toFixed(8);
      obj.amountB       = Number(ethers.utils.formatEther(amountB_BigN.toString())).toFixed(8);
      
      //console.log(obj);
      return (obj);
    }

    async function calTradePath(amount1, amount2, tradeType) { //Input unit: ether;
      var obj = 
      {
        amount: "",
        amountTolerance: "",
        path: [],
        route: []
      };
      //
      const WCRO  = new Token(ChainId.TESTNET, "0xC68495cB4e43d220720215624E83e5dda1a5E270", 18);
      const ptCRO = new Token(ChainId.TESTNET, "0x1Df21F91Bf57C5dA3F287835Fe885f7584f5c16A", 18);
      const ytCRO = new Token(ChainId.TESTNET, "0xa71fF1e273157be3D79fEE50AaBdb333eF4508DF", 18);
      const PAN   = new Token(ChainId.TESTNET, "0xC9fC9F4A9861DeA2042b2Ce8e29384108f56C8Bd", 18);
      //
      //V2
      const ptCROv2 = new Token(ChainId.TESTNET, "0x5549E74119896fDFDB63192bBf4FF6BD66709642", 18);
      const ytCROv2 = new Token(ChainId.TESTNET, "0x32CE9b776C60C20694284C8CCE0a76fd3059F465", 18);      
      //

      const tokenIN = new Token(ChainId.TESTNET, swapCoin1.address, swapCoin1.decimals);
      const tokenOUT= new Token(ChainId.TESTNET, swapCoin2.address,swapCoin2.decimals);

      const maxHops = multiHops ? 3 : 1 ; // Enable multiHops or not.

      try{
        const pair1   = await Fetcher.fetchPairData(ptCRO, WCRO, web3Provider);
        const pair2   = await Fetcher.fetchPairData(ytCRO, WCRO, web3Provider);
        const pair3   = await Fetcher.fetchPairData(PAN,   WCRO, web3Provider);
        //V2
        const pair4   = await Fetcher.fetchPairData(ptCROv2, WCRO, web3Provider);
        const pair5   = await Fetcher.fetchPairData(ytCROv2, WCRO, web3Provider);
        //

        if (tradeType===0) {  // EXACT INPUT

          const inputAmount = new TokenAmount(tokenIN, ethers.utils.parseEther(amount1.toString()));
          const trades = Trade.bestTradeExactIn([pair1, pair2, pair3, pair4, pair5], inputAmount, tokenOUT, { maxNumResults: 1, maxHops: maxHops });
          //
          if (trades && trades.length > 0) {
            const path = trades[0].route.path.map(token => token.address);
            const route = trades[0].route;
            const trade = new Trade(route, inputAmount, TradeType.EXACT_INPUT);
            const slippageTol = new Percent( slippageTolerance*1000, '1000');  // 0.50%

            obj.amount = trades[0].outputAmount.toSignificant(8);                     // Unit: ether
            obj.amountTolerance = trade.minimumAmountOut(slippageTol).raw.toString(); // Unit: wei
            obj.path = path;
            obj.route = trades[0].route;

            //console.log(tokenIN);
            //console.log(tokenOUT);
            //console.log(trades[0].inputAmount.toSignificant(8));
            //console.log(trades[0].outputAmount.toSignificant(8));
            //console.log(trades[0].priceImpact.toSignificant(3));
            //console.log(obj.amountTolerance);

            //console.log(obj);
            return (obj);
          } else {

            console.log('EXACT_INPUT: No trade found');
            return (obj);
          }
        }
        else {                // EXACT OUTPUT
          
          const outputAmount = new TokenAmount(tokenOUT, ethers.utils.parseEther(amount2.toString()));
          console.log(outputAmount.toSignificant(8));
          const trades = Trade.bestTradeExactOut([pair1, pair2, pair3], tokenIN, outputAmount, { maxNumResults: 1, maxHops: maxHops });
          //
          if (trades && trades.length > 0) {
            const path = trades[0].route.path.map(token => token.address);
            const route = trades[0].route;
            const trade = new Trade(route, outputAmount, TradeType.EXACT_OUTPUT);
            const slippageTol = new Percent( slippageTolerance*1000, '1000');  // 0.50%

            obj.amount = trades[0].inputAmount.toSignificant(8);                      // Unit: ether
            obj.amountTolerance = trade.maximumAmountIn(slippageTol).raw.toString();  // Unit: wei
            obj.path = path;
            obj.route = trades[0].route;

            //console.log(trades[0].inputAmount.toSignificant(8));
            //console.log(trades[0].outputAmount.toSignificant(8));
            //console.log(trades[0].priceImpact.toSignificant(3));
    
            //console.log(obj);
            return (obj);
          } else {

            console.log('EXACT_OUTPUT: No trade found');
            return (obj);
          }                    
        }
          
      } catch (error) {

        console.log(error);
        return (obj);
      }

    }

    async function TokenSwap(amount1, amount1Tolerance, amount2, amount2Tolerance, swapPath, tradeType) {
      console.log(amount1, amount1Tolerance, amount2, amount2Tolerance, swapPath, tradeType);

      const Router_contract = new ethers.Contract(uniswapRouterAddress, UniswapV2Router02_ABI, web3Provider.getSigner());

      const path = swapPath;
      const deadline = Math.floor(Date.now() / 1000) + 60 * 20;

      var amountIn;
      var amountOut;
      var amountOutMin;
      var amountInMax;

      var tx;
      if (tradeType===0) {  // EXACT INPUT
        amountIn     = ethers.utils.parseEther(amount1).toString();
        amountOut    = 0;
        amountOutMin = amount2Tolerance.toString();
        amountInMax  = 0;
      }
      else {                // EXACT OUTPUT
        amountIn     = 0;
        amountOut    = ethers.utils.parseEther(amount2).toString();;
        amountOutMin = 0;
        amountInMax  = amount1Tolerance.toString();        
      }

      // Transaction
      try{

        if (tradeType===0) {  // EXACT INPUT
          tx = await Router_contract.swapExactTokensForTokens(amountIn, amountOutMin, path, walletAddress, deadline, { gasLimit: 250000 });
        }

        if (tradeType===1) {  // EXACT OUTPUT
          tx = await Router_contract.swapTokensForExactTokens(amountOut, amountInMax, path, walletAddress, deadline, { gasLimit: 250000 });
        }

        if (typeof tx !== 'undefined') {
          console.log(`Transaction hash: ${tx.hash}`);
          return (tx);
        }
      } catch(error) {
          console.log(error);
          return ;
      }



    }

    async function SwapFunc(amount1, amount1Tolerance, amount2, amount2Tolerance, swapPath, tradeType) {
      var WCRO_contract;
      var depositAmount;
      var withdrawAmount;
      var tx;
      try{

        if      ((swapCoin1.symbol === "CRO")&&(swapCoin2.symbol === "WCRO")) {  // swap for CRO/WCRO
          WCRO_contract = new ethers.Contract(WCRO_address, WCRO_ABI, web3Provider.getSigner());
          depositAmount = ethers.utils.parseEther(amount1).toString();

          tx = await WCRO_contract.deposit( { value: depositAmount, gasLimit: 250000 });
        }
        else if ((swapCoin1.symbol === "WCRO")&&(swapCoin2.symbol === "CRO")) {  // swap for WCRO/CRO
          WCRO_contract  = new ethers.Contract(WCRO_address, WCRO_ABI, web3Provider.getSigner());
          withdrawAmount = ethers.utils.parseEther(amount1).toString();

          tx = await WCRO_contract.withdraw(withdrawAmount, { gasLimit: 250000 });          
        }
        else {
          tx = await TokenSwap(amount1, amount1Tolerance, amount2, amount2Tolerance, swapPath, tradeType);
        }

        if (typeof tx !== 'undefined') {
          console.log(`Transaction hash: ${tx.hash}`);
          return (tx);
        }
      } catch(error) {
        console.log(error);
        return ;
      }

    }

    async function addLiquidity(pair, amountADesired, amountBDesired) { //Input unit: ether;
      const Router_contract = new ethers.Contract(uniswapRouterAddress, UniswapV2Router02_ABI, web3Provider.getSigner());

      const tokenA = new Token(ChainId.TESTNET, pair.tokenA_addr,pair.tokenA_decimals);
      const tokenB = new Token(ChainId.TESTNET, pair.tokenB_addr,pair.tokenB_decimals);

      var amountADesired_BigN = BigNumber.from(ethers.utils.parseEther(amountADesired));
      var amountBDesired_BigN = BigNumber.from(ethers.utils.parseEther(amountBDesired));

      const amountAMin = amountADesired_BigN.mul( (1-Number(slippageTolerance))*1000 ).div(1000);
      const amountBMin = amountBDesired_BigN.mul( (1-Number(slippageTolerance))*1000 ).div(1000);
      const deadline = Math.floor(Date.now() / 1000) + 60 * 20;           

      try{
        var tx = await Router_contract.addLiquidity(pair.tokenA_addr, pair.tokenB_addr, amountADesired_BigN.toString(), amountBDesired_BigN.toString(),
                                                    amountAMin.toString(), amountBMin.toString(), walletAddress, deadline, { gasLimit: 250000 });

        if (typeof tx !== 'undefined') {
          console.log(`Transaction hash: ${tx.hash}`);
          return (tx);
        }
      } catch(error) {
          console.log(error);
          return ;
      }

    }

    async function removeLiquidity(pair, amountLPDesired, amountADesired, amountBDesired) { //Input unit: ether;
      const Router_contract = new ethers.Contract(uniswapRouterAddress, UniswapV2Router02_ABI, web3Provider.getSigner());

      const amountLP_BigN = BigNumber.from(ethers.utils.parseEther(amountLPDesired));
      const amountA_BigN  = BigNumber.from(ethers.utils.parseEther(amountADesired));
      const amountB_BigN  = BigNumber.from(ethers.utils.parseEther(amountBDesired));
      const amountAMin = amountA_BigN.mul( (1-Number(slippageTolerance))*1000 ).div(1000);
      const amountBMin = amountB_BigN.mul( (1-Number(slippageTolerance))*1000 ).div(1000);
      const deadline = Math.floor(Date.now() / 1000) + 60 * 20;

      try{
        var tx = await Router_contract.removeLiquidity(pair.tokenA_addr, pair.tokenB_addr, amountLP_BigN.toString(), amountAMin.toString(), amountBMin.toString(), walletAddress, deadline, { gasLimit: 250000 });
        
        if (typeof tx !== 'undefined') {
          console.log(`Transaction hash: ${tx.hash}`);
          return (tx);
        }
      } catch(error) {
        console.log(error);
        return ;
      }

    }

  /* Temp original
    async function exactInSwap(amountIn) {
      console.log(ChainId.TESTNET);
      console.log(uniswapRouterAddress);

      const Router_contract = new ethers.Contract(uniswapRouterAddress, UniswapV2Router02_ABI, web3Provider.getSigner());

      const tokenIN = new Token(ChainId.TESTNET, swapCoin1.address, swapCoin1.decimals);
      const tokenOUT= new Token(ChainId.TESTNET, swapCoin2.address,swapCoin2.decimals);
      const path = [tokenIN.address, tokenOUT.address];
      const pair    = await Fetcher.fetchPairData(tokenOUT, tokenIN, web3Provider);
      const IN_to_OUT = new Route([pair], tokenIN);
      const trade = new Trade(IN_to_OUT, new TokenAmount(tokenIN, amountIn), TradeType.EXACT_INPUT);

      const slippageTol = new Percent( slippageTolerance*1000, '1000');  // 0.50%
      const amountOutMin = trade.minimumAmountOut(slippageTol).raw.toString();
      const deadline = Math.floor(Date.now() / 1000) + 60 * 20;

      console.log(swapCoin1.symbol, swapCoin2.symbol);
      console.log(path);
      console.log(amountIn);
      console.log(amountOutMin);
      console.log(walletAddress);
      console.log(amountOutMin, (trade.outputAmount.toSignificant(6)*1e18));
      console.log(deadline, Math.floor(Date.now() / 1000));

      // Transaction
      const tx = await Router_contract.swapExactTokensForTokens(amountIn, amountOutMin, path, walletAddress, deadline, { gasLimit: 250000 });
      console.log(`Transaction hash: ${tx.hash}`);
    
      // Wait for confirmation
      const receipt = await tx.wait();
      console.log(`Transaction confirmed in block: ${receipt.blockNumber}`);

      return (receipt.blockNumber);
    }


    async function addLiquidity(tokenAAddress, tokenBAddress, amountADesired) {
      console.log(ChainId.TESTNET);
      console.log(uniswapRouterAddress);

      const Router_contract = new ethers.Contract(uniswapRouterAddress, UniswapV2Router02_ABI, web3Provider.getSigner());

      const tokenA = new Token(ChainId.TESTNET, tokenAAddress.address, tokenAAddress.decimals);
      const tokenB = new Token(ChainId.TESTNET, tokenBAddress.address,tokenBAddress.decimals);

      const pair = await Fetcher.fetchPairData(tokenA, tokenB, web3Provider);

      const reserve0 = pair.reserve0.toFixed(tokenAAddress.decimals);
      const reserve1 = pair.reserve1.toFixed(tokenBAddress.decimals);

      //console.log(pair);
      console.log(reserve0.toString(), reserve1.toString());

      var amountADesired_BigN = BigNumber.from(amountADesired);
      const reserve0_BigN = BigNumber.from(ethers.utils.parseEther(reserve0));
      const reserve1_BigN = BigNumber.from(ethers.utils.parseEther(reserve1));

      var amountBDesired;
      if (pair.token0.address === tokenAAddress.address) {
        console.log(pair.token0.address, tokenAAddress.address, 1);
        amountBDesired = amountADesired_BigN.mul(reserve1_BigN).div(reserve0_BigN);
      }
      else {
        console.log(pair.token0.address, tokenAAddress.address, 2);
        amountBDesired = amountADesired_BigN.mul(reserve0_BigN).div(reserve1_BigN);
      }

      const amountAMin = amountADesired_BigN * ( 1-Number(slippageTolerance) );
      const amountBMin = amountBDesired * ( 1-Number(slippageTolerance) );
      const deadline = Math.floor(Date.now() / 1000) + 60 * 20;           

      const pairTotalSupply = await getTotalSupply(pair.liquidityToken.address);
      console.log(pair.liquidityToken.address, pairTotalSupply);

      console.log(tokenA, tokenB);
      console.log(amountADesired_BigN.toString(), amountBDesired.toString());
      console.log(amountAMin.toString(), amountBMin.toString());
      console.log(deadline, Math.floor(Date.now() / 1000));


      //console.log(ethers.utils.formatEther(amountADesired.toString()));

      var AllowA = await checkAllowance(tokenAAddress.address,ethers.utils.formatEther(amountADesired.toString()),uniswapRouterAddress);
      var AllowB = await checkAllowance(tokenBAddress.address,ethers.utils.formatEther(amountBDesired.toString()),uniswapRouterAddress);

      if (AllowA === false) {
        var tx = await approveToken(tokenAAddress.address, uniswapRouterAddress, ethers.utils.formatEther(amountADesired.toString()));
        const receipt = await tx.wait();
        console.log(`Transaction confirmed in block: ${receipt.blockNumber}`);
      }

      if (AllowB === false) {
        var tx = await approveToken(tokenBAddress.address, uniswapRouterAddress, ethers.utils.formatEther(amountBDesired.toString()));
        const receipt = await tx.wait();
        console.log(`Transaction confirmed in block: ${receipt.blockNumber}`);
      }

      var AllowA = await checkAllowance(tokenAAddress.address,ethers.utils.formatEther(amountADesired.toString()),uniswapRouterAddress);
      var AllowB = await checkAllowance(tokenBAddress.address,ethers.utils.formatEther(amountBDesired.toString()),uniswapRouterAddress);

      console.log(AllowA, AllowB);


      //var tx = await Router_contract.addLiquidity(tokenAAddress.address, tokenBAddress.address, amountADesired_BigN.toString(), amountBDesired.toString(),
      //                                             amountAMin.toString(), amountBMin.toString(), walletAddress, deadline, { gasLimit: 250000 });
      //console.log(`Transaction hash: ${tx.hash}`);
    
      // Wait for confirmation
      //const receipt = await tx.wait();
      //console.log(`Transaction confirmed in block: ${receipt.blockNumber}`);

      //return (receipt.blockNumber);
    }

    async function removeLiquidity(tokenAAddress, tokenBAddress, userLiquidity) {
      const Router_contract = new ethers.Contract(uniswapRouterAddress, UniswapV2Router02_ABI, web3Provider.getSigner());

      const tokenA = new Token(ChainId.TESTNET, tokenAAddress.address, tokenAAddress.decimals);
      const tokenB = new Token(ChainId.TESTNET, tokenBAddress.address,tokenBAddress.decimals);
      const pair = await Fetcher.fetchPairData(tokenA, tokenB, web3Provider);

      const totalLiquidity = await getTotalSupply(pair.liquidityToken.address);
      const reserve0       = pair.reserve0.toFixed(tokenAAddress.decimals);
      const reserve1       = pair.reserve1.toFixed(tokenBAddress.decimals);
      const reserve0_BigN  = BigNumber.from(ethers.utils.parseEther(reserve0));
      const reserve1_BigN  = BigNumber.from(ethers.utils.parseEther(reserve1));

      var userLiquidity_BigN  = BigNumber.from(userLiquidity);
      var totalLiquidity_BigN = BigNumber.from(ethers.utils.parseEther(totalLiquidity));
      var percentage_BigN = userLiquidity_BigN.mul(10000).div(totalLiquidity_BigN); //percentage*100

      const amountA_BigN = reserve0_BigN.mul(userLiquidity_BigN).div(totalLiquidity_BigN);
      const amountB_BigN = reserve1_BigN.mul(userLiquidity_BigN).div(totalLiquidity_BigN);
      const amountAMin = amountA_BigN * ( 1-Number(slippageTolerance) );
      const amountBMin = amountB_BigN * ( 1-Number(slippageTolerance) );
      const deadline = Math.floor(Date.now() / 1000) + 60 * 20;

      console.log(totalLiquidity_BigN.toString(), userLiquidity_BigN.toString(), Number(percentage_BigN)/100);
      console.log(reserve0_BigN.toString(), reserve1_BigN.toString());
      console.log(pair.token0.address, pair.token1.address);
      console.log(amountA_BigN.toString(), amountB_BigN.toString());
      console.log(amountAMin.toString(), amountBMin.toString());


      var Allow = await checkAllowance(pair.liquidityToken.address,ethers.utils.formatEther(totalLiquidity_BigN.toString()),uniswapRouterAddress);

      if (Allow === false) {
        var tx = await approveToken(pair.liquidityToken.address, uniswapRouterAddress, ethers.utils.formatEther(totalLiquidity_BigN.toString()));
        const receipt = await tx.wait();
        console.log(`Transaction confirmed in block: ${receipt.blockNumber}`);
      }

      var Allow = await checkAllowance(pair.liquidityToken.address,ethers.utils.formatEther(totalLiquidity_BigN.toString()),uniswapRouterAddress);
      
      console.log(Allow);


      var tx = await Router_contract.removeLiquidity(pair.token0.address, pair.token1.address, userLiquidity_BigN.toString(), amountAMin.toString(), amountBMin.toString(), walletAddress, deadline, { gasLimit: 250000 });
      console.log(`Transaction hash: ${tx.hash}`);
    
      // Wait for confirmation
      const receipt = await tx.wait();
      console.log(`Transaction confirmed in block: ${receipt.blockNumber}`);

      return (receipt.blockNumber);
    }

    async function calAmountOUT(amount, inputToken, outputToken, tradeType) { //Input unit: ether;
      var obj = 
      {
        amount: "",
        amountTolerance: ""
      };

      if ((inputToken.symbol === "CRO")||(outputToken.symbol === "CRO")) {  // rule for CRO
        obj.amount = amount;
        obj.amountTolerance = amount;
        
        return (obj);
      }

      const tokenA = new Token(ChainId.TESTNET, inputToken.address, inputToken.decimals);
      const tokenB = new Token(ChainId.TESTNET, outputToken.address,outputToken.decimals);
      const amount_Wei = ethers.utils.parseEther(amount);
      try {
        const pair = await Fetcher.fetchPairData(tokenA, tokenB, web3Provider);

        var IN_to_OUT;
        var trade;
        var slippageTol;
        var amountOutMin;
        var amountOut;
        var amountInMax;
        var amountIn;

        if (tradeType===0) {  // EXACT INPUT
          IN_to_OUT = new Route([pair], tokenA);
          trade = new Trade(IN_to_OUT, new TokenAmount(tokenA, amount_Wei), TradeType.EXACT_INPUT);

          slippageTol = new Percent( slippageTolerance*1000, '1000');         // 0.50%
          amountOut = Number(trade.outputAmount.toSignificant(18));           // Unit: ether
          amountOutMin = trade.minimumAmountOut(slippageTol).raw.toString();  // Unit: wei

          obj.amount = amountOut;
          obj.amountTolerance = Number(ethers.utils.formatEther(amountOutMin)); 
          
          //return (ethers.utils.formatEther(amountOutMin));
  
          return (obj);
        }
        else {                // EXACT OUTPUT
          IN_to_OUT = new Route([pair], tokenA);
          trade = new Trade(IN_to_OUT, new TokenAmount(tokenB, amount_Wei), TradeType.EXACT_OUTPUT);

          console.log(trade);

          slippageTol = new Percent( slippageTolerance*1000, '1000');         // 0.50%
          amountIn = Number(trade.inputAmount.toSignificant(18));           // Unit: ether
          amountInMax = trade.maximumAmountIn(slippageTol).raw.toString();  // Unit: wei
   
          obj.amount = amountIn;
          obj.amountTolerance = Number(ethers.utils.formatEther(amountInMax)); 

          //return (ethers.utils.formatEther(amountInMax));
  
          return (obj);          
        }    
      } catch (error) {

        if (error.toString()==="InsufficientReservesError") {
          console.log("Insufficient reserves in the liquidity pool.");
        } else {
          console.log('calAmountOUT error occurred:', error);
        }

        return (obj);
      }

    }

*/

  return {
    calAmountOUT,
    calLiquidity,
    calLPShare,
    calTradePath,
    TokenSwap,
    SwapFunc,
    addLiquidity,
    removeLiquidity
  };
}
