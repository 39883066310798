import { Box } from "@chakra-ui/react";

const CustomSwitchLarge = ({ checkedBackground, uncheckedBackground, isChecked, toggleSwitch}) => {

  return (
    <Box
      width="36px"
      height="20px"
      borderRadius="10px"
      bg={isChecked ? checkedBackground : uncheckedBackground}
      position="relative"
      onClick={toggleSwitch}
      cursor="pointer"
    >
      <Box
        position="absolute"
        top="2px"
        left={isChecked ? "18px" : "2px"}
        transition="left 0.2s"
        width="16px"
        height="16px"
        borderRadius="50%"
        bg="white"
      />
    </Box>
  );
};

export default CustomSwitchLarge;
