export const logoMapping = {
    "Cronos"             : require("./assets/CRO.png"),
    "Wrapped Cronos"     : require("./assets/WCRO.png"),
    "principle Cronos V1": require("./assets/ptCRO.png"),
    "yield Cronos V1"    : require("./assets/ytCRO.png"),
    "PAN Token"          : require("./assets/creampan_color2_2.png"),
    "principle Cronos V2": require("./assets/ptCROv2.png"),
    "yield Cronos V2"    : require("./assets/ytCROv2.png"),

  };
  