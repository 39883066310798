import { useContext } from "react";
import { Button, Box, Spinner, useDisclosure} from "@chakra-ui/react";
import { AppContext } from "../context";
import { useWeb3 } from "../hooks/useWeb3";

export default function MintButtonNFT({ onClick, isLoading, text, allowed, amount }) {
  const {
    web3Provider,
    isWalletOpen,
    setIsWalletOpen
  } = useContext(AppContext);

  const { connectWeb3 } = useWeb3();

  return web3Provider ? (
    <Box p="0.5rem" bg="rgb(248,233,211)" borderRadius="0 0 1.25rem 1.25rem">

      <Button
        bg={ (allowed ? "rgb(220,220,220)" : "rgb(255,0,122)") }
        width="100%"
        p="1.62rem"
        borderRadius="1.25rem"
        color={isLoading || amount==="" || (Number(amount)===0)  ? "#FFFFFF"                  : (allowed ? "#007AFF" : "#FFFFFF") }
        _hover={isLoading || amount==="" || (Number(amount)===0) ? { bg: "rgb(220,220,220)" } : (allowed ? {bg:"rgb(160,190,190)"} : {bg:"rgb(220,40,112)"}) }
        onClick={isLoading || amount==="" || (Number(amount)===0) ?  null                      : onClick}
        disabled={isLoading || amount==="" || (Number(amount)===0) }
        rightIcon={isLoading ? <Spinner size="sm" speed='0.8s'/> : null}
      >
        {text}
      </Button>
    </Box>
  ) : (
    <Box p="0.5rem" bg="white" borderRadius="0 0 1.25rem 1.25rem">
      <Button
        color="white"
        bg="orange"
        width="100%"
        p="1.62rem"
        borderRadius="1.25rem"
        _hover={{ bg: "orange.400" }}
        onClick={ () => {setIsWalletOpen(true)} }
      >
        Connect Wallet
      </Button>
    </Box>
  );
}
